import firebase from '@firebase/app'

import '@firebase/firestore'
import '@firebase/auth'

let config = {
  apiKey: 'AIzaSyDFIvSamGOdiPmnpBC8Bdv4le4urwUbjaE',
  authDomain: 'presscoins-app.firebaseapp.com',
  databaseURL: 'https://presscoins-app.firebaseio.com',
  projectId: 'presscoins-app',
  storageBucket: 'presscoins-app.appspot.com',
  messagingSenderId: '941838238914'
}

firebase.initializeApp(config)

const firebaseDb = firebase.firestore()
const firebaseAuth = firebase.auth()

// firebase collections
let userCollection = firebaseDb.collection('users')
let presscoinsCollection = firebaseDb.collection('presscoins')

export {
  firebaseDb,
  firebaseAuth,
  userCollection,
  presscoinsCollection
}
