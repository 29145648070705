<template>
  <div>
    <b-link @click.stop="removeFromCollection()" v-b-tooltip.hover title="Remove Coin" class="pc-coin-delete text-danger">
      <font-awesome-icon :icon="['far', 'trash']" />
    </b-link>
    <!-- <b-modal :id="'coin-remove-' + p.catalog" centered title="Are you sure you want to remove this coin?">
      <p>Removing coins from your collection will remove any associated notes and quantites.</p>
    </b-modal> -->
  </div>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig

export default {
  name: 'RemoveFromCollectionBtn',
  props: {
    catalog: {
      type: String,
      default: 'MAAK0018'
    }
  },
  methods: {
    removeFromCollection () {
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .doc(this.catalog)
        .delete()
        .then(() => {
          console.log('Document successfully deleted!')
          this.$bvToast.toast(`Coin removed successfully.`, {
            title: 'Coin Removed',
            toaster: 'b-toaster-bottom-right',
            autoHideDelay: 5000
          })
        }).catch(function (error) {
          console.error('Error removing document: ', error)
        })
    }
  }
}
</script>

<style lang="scss">
.pc-coin-delete {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2)
  }
}
</style>
