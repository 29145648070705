<template>
  <div class="pressed-coin-map text-center">
    <ais-instant-search
    :search-client="searchClient"
    index-name="prod_PRESSCOINS_MAP"
    >
    <!-- :routing="routing" -->
    <ais-configure :filters="'available:True'" :aroundLatLngViaIP="true" :getRankingInfo="true"></ais-configure>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="8" order-md="2" class="p-0">
            <app-map />
          </b-col>
          <b-col cols="12" md="4" class="pc-map__search-panel p-0">
            <div class="pc-map__search-tools">
              <div class="pc-search-box">
                <b-input-group class="pc-search__input-group">
                  <ais-search-box show-loading-indicator class="d-block">
                    <input
                      slot-scope="{ currentRefinement, isSearchStalled, refine }"
                      :value="currentRefinement"
                      @input="refine($event.currentTarget.value)"
                      type="search"
                      placeholder='Try "Tomorrowland" or "Peter Pan"'
                      autocomplete="off"
                      class="form-control d-block"
                      style="border-radius: 6rem 0 0 6rem"
                    />
                    <span :hidden="!isSearchStalled">Loading...</span>
                  </ais-search-box>
                  <b-input-group-append>
                    <b-button variant="primary" class="py-0 px-3"><font-awesome-icon :icon="['far', 'search']" class="mr-1"></font-awesome-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <ais-refinement-list
              attribute="place"
              :limit="5"
              class="pc-map__refinement-list"
              :class-names="{
                'ais-RefinementList-checkbox': 'd-none',
                'ais-RefinementList-label': 'pc-map__labl',
                'ais-RefinementList-labelText': 'pc-map__label-text',
                'ais-RefinementList-count': 'd-none badge badge-secondary',
                'ais-RefinementList-list': 'pc-map__list',
                'ais-RefinementList-item': 'pc-map__list-item',
                'ais-RefinementList-item--selected': 'pc-map__list-item--selected'
              }"
              >
              </ais-refinement-list>
            </div>
            <ais-state-results>
              <template slot-scope="{ query, hits }">
                <div v-if="hits.length">
                  <ais-infinite-hits
                    class="mb-2"
                    :class-names="{
                      'ais-InfiniteHits-loadMore': 'btn btn-primary mt-2 mx-auto',
                      'ais-InfiniteHits-item': 'list-group-item list-group-item-action text-left',
                      'ais-InfiniteHits-loadMore--disabled': 'd-none'
                    }">
                    <!-- @click="center(item._geoloc)" -->
                    <!-- router-link :to="'/machine/'  -->
                    <router-link :to="'/machine/' + item.machine" slot="item" slot-scope="{ item }" class="pc-search__item">
                      <b-container>
                        <b-row align-v="center">
                          <b-col cols="2" class="p-0">
                            <b-img :src="'https://res.cloudinary.com/presscoins/image/upload/d_contentNodes:presscoins-default.png/c_fill,h_100,q_auto,w_100/v1535414476/machines/' + item.machine + '.jpg'" rounded="circle" blank-color="#777" :alt="item.machine" fluid></b-img>
                          </b-col>
                          <b-col cols="10" class="pr-0">
                            <small class="d-block">{{item.place}}</small>
                            <h5 class="mb-1 d-inline-block pc-search_title"><add-to-collection-btn :catalog="item.catalog" :title="item.shortesc" :coinType="item.coin" :buttonSmall="true" class="d-inline-block mr-1"></add-to-collection-btn>{{item.location}}</h5>
                            <small class="d-block text-muted" v-if="item._rankingInfo.geoDistance <= 2000000">{{item._rankingInfo.geoDistance | units('m', 'mi', false) | numFormat('0.0') }} mi. away</small>
                          </b-col>
                        </b-row>
                      </b-container>
                    </router-link>
                  </ais-infinite-hits>
                </div>
                <div class="text-center" v-if="!hits.length">
                  <img src="../assets/img/machine-waypoint.png" alt="404 Uh-oh" style="width:100%;max-width:10rem" />
                  <h2>No pressed coins found for <i>{{ query }}</i>.</h2>
                </div>
              </template>
            </ais-state-results>
          </b-col>
        </b-row>
      </b-container>
    </ais-instant-search>
    <!-- <PresscoinsMap :location="this.location"></PresscoinsMap> -->
  </div>
</template>

<script>
import AddToCollectionBtn from '@/components/AddToCollectionBtn'
// import { history } from 'instantsearch.js/es/lib/routers'
// import { simple } from 'instantsearch.js/es/lib/stateMappings'
import algoliasearch from 'algoliasearch/lite'
// import 'instantsearch.css/themes/algolia-min.css'
// import PresscoinsMap from '@/components/PresscoinsMap'
import AppMap from '@/components/Map'

export default {
  name: 'Map',
  data () {
    return {
      location: 'Magic Kingdom',
      searchClient: algoliasearch(
        'TH30VT8HCR',
        'b408b316ebe31b3e42ffaedd9e977f78'
      )
      // routing: {
      //   router: history(),
      //   stateMapping: simple()
      // }
    }
  },
  metaInfo: {
    title: 'Coin Press Map',
    meta: [
      { name: 'description', content: 'Find Walt Disney World coin press machines near me.' }
    ]
  },
  components: {
    // PresscoinsMap,
    AddToCollectionBtn,
    AppMap
  }
  // methods: {
  //   // panTo (geoloc){
  //   //   this.$root.$emit('updatePosition', geoloc);
  //   //   console.log('fire')
  //   // }
  //   panTo (geoloc){
  //     this.$refs.map.updateCenter(geoloc)
  //     // map.panTo(geoloc)
  //     // setCenter
  //     // this.$root.$refs.myRef
  //     // this.$root.$emit('updatePosition', geoloc);
  //     console.log(geoloc)
  //   }
  // }
}
</script>

<style lang="scss">
.pc-map__refinement-list {
  width: 100%;
  height: 4rem;
  overflow: auto;
  overflow-y: hidden;
  background: #fff;
}

.pc-map__list {
  display: flex;
  padding: 0;
  margin: 0;
  height: 4rem;
  width: 30rem;

}

.pc-search-box {
  padding: 0.5rem;
  background: lighten(#17213a, 10%);
  overflow: hidden;
}

.pc-map__list-item {
  display: inline-flex;
  // width: 6rem;
  // justify-content: center;
  // border-right: 1px solid rgba(0,0,0,0.1);
  // font-size: 0.8rem;
  // align-items: center;
  height: 100%;
  // padding: 0.25rem;
}

.pc-map__list-item--selected {
  background: #296ef6;
  color: #fff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: lighten(#296ef6, 10%);
  }
}

.pc-map__search-panel {
  height: calc(100vh - 5.75rem);
  overflow: scroll;
}

.pc-map__search-tools {
  position: sticky;
  background: #fff;
  top: 0;
  z-index: 99;
  box-shadow: 0 10px 20px rgba(41, 110, 246,0.19), 0 6px 6px rgba(41, 110, 246,0.23);
}

.pc-map__label {
  margin-bottom: 0;
}

.pc-map__label-text {
  line-height: 1.2;
  cursor: pointer;
  height: 100%;
  display: inline-flex;
  width: 6rem;
  height: 4rem;
  justify-content: center;
  border-right: 1px solid rgba(0,0,0,0.1);
  font-size: 0.8rem;
  align-items: center;
  text-align: center;
  position: relative;
  // background: rgba(#296ef6, 0.1);
  // border-radius: 3px;
  // height: 100%;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: rgba(#296ef6, 0.1);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: #296ef6;
    }

  }
}
</style>
