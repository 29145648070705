<template>
    <span class="pc-user-coin-collection-count">{{ this.collectionTotal | numFormat }}</span>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig

export default {
  name: 'UserCoinCollectionCount',
  data () {
    return {
      collectionTotal: 0 // Empty array to populate from firestore
    }
  },
  methods: {
    getPresscoins: function () {
      userCollection
        .doc(this.$store.state.user.uid)
        // .collection('userCoinCollection')
        .get().then((doc) => {
          if (doc.exists) {
            this.collectionTotal = doc.data().collectionTotal
            console.log('Document data: ', doc.data())
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        }).catch(function (error) {
          console.log('Error getting document: ', error)
        })
      // .where('quantity', '==', 1)
      // .onSnapshot(querySnapshot => {
      //   let presscoinsArray = []

      //   querySnapshot.forEach(doc => {
      //     let coin = doc.data()
      //     coin.id = doc.id
      //     presscoinsArray.push(coin)
      //   })

      //   this.presscoins = presscoinsArray
      // })
    }
  },
  beforeMount () {
    this.getPresscoins()
  },
  computed: {
    numCollection () {
      return this.presscoins.length
    }
  }
}
</script>

<style lang="scss">
.pc-coin-count {
  display: inline-flex;
}
</style>
