<template>
  <div v-if="state" class="ais-GeoSearch">
    <googlemaps-map
      ref="map"
      :center="center"
      :zoom.sync="zoom"
      :options='{
                mapTypeControl: true,
                mapTypeId: "roadmap",
                streetViewControl: true,
                rotateControl: false,
                fullscreenControl: true,
                styles: [
                          {
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#8ec3b9"
                              }
                            ]
                          },
                          {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1a3646"
                              }
                            ]
                          },
                          {
                            "featureType": "administrative.country",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#4b6878"
                              }
                            ]
                          },
                          {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#64779e"
                              }
                            ]
                          },
                          {
                            "featureType": "administrative.province",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#4b6878"
                              }
                            ]
                          },
                          {
                            "featureType": "landscape.man_made",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#334e87"
                              }
                            ]
                          },
                          {
                            "featureType": "landscape.natural",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#023e58"
                              }
                            ]
                          },
                          {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#283d6a"
                              }
                            ]
                          },
                          {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#6f9ba5"
                              }
                            ]
                          },
                          {
                            "featureType": "poi",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [
                              {
                                "color": "#023e58"
                              }
                            ]
                          },
                          {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#3C7680"
                              }
                            ]
                          },
                          {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#304a7d"
                              }
                            ]
                          },
                          {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#98a5be"
                              }
                            ]
                          },
                          {
                            "featureType": "road",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#2c6675"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#255763"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#b0d5ce"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#023e58"
                              }
                            ]
                          },
                          {
                            "featureType": "transit",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#98a5be"
                              }
                            ]
                          },
                          {
                            "featureType": "transit",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "featureType": "transit.line",
                            "elementType": "geometry.fill",
                            "stylers": [
                              {
                                "color": "#283d6a"
                              }
                            ]
                          },
                          {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#3a4762"
                              }
                            ]
                          },
                          {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#0e1626"
                              }
                            ]
                          },
                          {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#4e6d70"
                              }
                            ]
                          }
                        ]}'
      class="ais-GeoSearch-map"
    >

      <googlemaps-user-position
        @update:position="setUserPosition"
      />

      <!-- :label="{
          text: item.location,
          color: '#ffffff',
          fontSize: '16px',
          fontWeight: 'bold',
          strokeColor: 'black',
          strokeWeight: 1,
          @click="$router.push('/machine/' + item.machine)"
        }" -->

      <googlemaps-marker
        v-for="item of state.items"
        @click="selectMarker(item)"
        :key="item.objectID"
        :ref="item.objectID"
        :title="item.location"
        :position="item._geoloc"
        :icon="{url : require('../assets/img/machine-marker.png'),labelOrigin: {x: 80, y: 80}}"
      />
    </googlemaps-map>
  </div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch'
import { connectGeoSearch } from 'instantsearch.js/es/connectors'

export default {
  mixins: [createWidgetMixin({ connector: connectGeoSearch })],
  data () {
    return {
      zoom: 17
    }
  },
  computed: {
    center () {
      return this.state.items.length !== 0
        ? this.state.items[0]._geoloc
        : { lat: 28.4177, lng: -81.5812 }
    }
  },
  methods: {
    selectMarker (marker) {
      var map = this.$refs.map.$_map
      var markerEle = this.$refs[marker.objectID][0].$_marker
      var contentString = '<a href="/machine/' + marker.machine + '"><img src="https://res.cloudinary.com/presscoins/image/upload/d_contentNodes:presscoins-default.png/c_fill,h_100,q_auto,w_100/v1535414476/machines/' + marker.machine + '.jpg" class="mb-2 img-fluid rounded-circle"/></a><h6>' + marker.location + '</h6><p>' + marker.place + '</p><a href="/machine/' + marker.machine + '">View Machine</a>'
      // eslint-disable-next-line
      var infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 200
      })
      infowindow.open(map, markerEle)
    }
    // updatePosition (geoloc){
    //   console.log('heard')
    //   var map = this.$refs.map.$_map
    //   // var map = this.$refs['map']
    //   // map.panTo(geoloc)
    //   // setCenter
    //   map.panTo(geoloc)
    //   console.log('update pos')
    // }
  }
}
</script>

<style>
.ais-GeoSearch {
  height: 20rem;
  display: flex;
  flex-direction: column;
}
@media(min-width:768px) {
  .ais-GeoSearch {
    height: calc(100vh - 5.75rem);
  }
}
.ais-GeoSearch-map {
  flex: 100% 1 1;
}
.my-custom-class-for-label {
  color: purple!important;
  background: yellow;
}
</style>
