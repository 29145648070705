<template>
  <div class="pc-coin-fav-container">
    <b-link v-if="!isAuthenticated" to="/login" v-b-tooltip.hover title="Love Coin" class="pc-coin-favorite" :class="{ 'pc-coin-favorite--inline' : inline, 'pc-coin-favorite--large' : size == 'large'  }">
      <font-awesome-icon :icon="['far', 'heart']" />
    </b-link>

    <b-link v-if="!this.status && isAuthenticated" @click.stop="addToFavList()" v-b-tooltip.hover title="Love Coin" class="pc-coin-favorite" :class="{ 'pc-coin-favorite--inline' : inline, 'pc-coin-favorite--large' : size == 'large'  }">
      <font-awesome-icon :icon="['far', 'heart']" />
    </b-link>

    <b-link v-if="this.status && isAuthenticated" @click.stop="removeFromFavList()" v-b-tooltip.hover title="Remove Love" class="pc-coin-favorite" :class="{ 'pc-coin-favorite--inline' : inline, 'pc-coin-favorite--large' : size == 'large' }">
      <font-awesome-icon :icon="['fas', 'heart']" />
    </b-link>
  </div>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig

export default {
  name: 'AddCoinFavBtn',
  data () {
    return {
      status: false
    }
  },
  props: {
    catalog: {
      type: String,
      default: 'MAAK0018'
    },
    inline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'regular'
    }
  },
  methods: {
    getStatus: function () {
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .doc(this.catalog)
        .get().then((doc) => {
          if (doc.exists) {
            this.status = doc.data().favorite
            // console.log('Document data: ', doc.data())
          } else {
            // doc.data() will be undefined in this case
            // console.log('No such document!')
          }
        }).catch(function (error) {
          console.log('Error getting document: ', error)
        })
    },
    addToFavList () {
      const createdAt = new Date()
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .doc(this.catalog)
        .set({ catalog: this.catalog, date_favorited: createdAt, favorite: true }, { merge: true })
        .then(() => {
          // console.log('Coin successfully added!')
          this.getStatus()
          // this.$bvToast.toast(`Coin added successfully.`, {
          //   title: 'Coin Removed',
          //   autoHideDelay: 5000
          // })
        }).catch(function (error) {
          console.error('Error adding coin: ', error)
        })
    },
    removeFromFavList () {
      const createdAt = new Date()
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .doc(this.catalog)
        .update({ catalog: this.catalog, date_unfavorited: createdAt, favorite: false })
        .then(() => {
          // console.log('Coin successfully deleted!')
          this.getStatus()
          // this.$bvToast.toast(`Coin deleted successfully.`, {
          //   title: 'Coin Added',
          //   autoHideDelay: 5000
          // })
        }).catch(function (error) {
          console.error('Error deleting coin: ', error)
        })
    }
  },
  beforeMount () {
    this.getStatus()
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated()
    }
  }
}
</script>

<style lang="scss">
.pc-coin-fav-container {
  display: inline-flex;
  vertical-align: middle;
}
.pc-coin-favorite {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
  color: #f9b536;

  &:hover {
    color: lighten(#f9b536, 10%);
    transform: scale(1.2)
  }

  &.pc-coin-favorite--inline {
    position: static;
    display: inline-flex;
  }

  &.pc-coin-favorite--large {
    font-size: 1.75rem;
  }
}
</style>
