<template>
  <b-container class="bv-example-row my-5 text-center">
    <b-row>
        <b-col>
          <img src="../assets/img/machine-waypoint.png" alt="404 Uh-oh" style="max-height: 10rem" />
          <h1>Uh-oh!</h1>
          <p>Page Not Found</p>
          <b-button to="/" variant="primary">Beam Me Up!</b-button>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: '404',
    meta: [
      { name: 'description', content: 'Page could not be found.' }
    ]
  }
}
</script>
