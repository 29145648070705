<template>
  <div class="pc-search-bar">
    <b-form @submit.prevent="submit()">
      <label>Search 2,000+ pressed coins</label>
      <b-input-group class="pc-search__input-group">
        <b-form-input type="search" size="lg" class="pc-search-bar__input" placeholder="Try Mickey, Star Wars, or WDW19068" v-model="search" autocomplete="off"></b-form-input>

        <b-input-group-append>
          <b-dropdown text="Availbility" class="d-none d-md-inline-flex" variant="light">
            <b-dropdown-item @click="available = 'True'">Current</b-dropdown-item>
            <b-dropdown-item @click="available = 'False'">Retired</b-dropdown-item>
          </b-dropdown>
          <b-button variant="primary" @click="submit()" class="px-3"><font-awesome-icon :icon="['far', 'search']" class="mr-1"></font-awesome-icon><span class="d-none d-md-inline-block">Search</span></b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  data () {
    return {
      search: '',
      available: ''
    }
  },
  methods: {
    submit () {
      this.$router.push('/search/?query=' + this.search + '&refinementList%5Bavailable%5D%5B0%5D=' + this.available)
    }
  }
}
</script>

<style lang="scss">
.pc-search__input-group {
  border-radius: 6rem;
  box-shadow: 0 19px 38px rgba(#296ef6,0.30), 0 15px 12px rgba(#296ef6,0.22);
}
.pc-search-bar__input {
  border-radius: 6rem 0 0 6rem!important;
}
</style>
