<template>
  <div class="news">
    <b-jumbotron
      style="background: url(https://res.cloudinary.com/presscoins/image/upload/v1568166115/contentNodes/presscoins-walt-disney-world-pressed-coin-news.jpg) no-repeat center / cover"
      header="Pressed Coin News"
      lead="Treasured scoop about Walt Disney World’s best memory making souvenirs."
      class="text-white mb-0"
      fluid>
    </b-jumbotron>

 <b-tabs pills card>
    <b-tab title="All" active>
      <b-container>
        <b-row>
          <b-col lg="6" v-for="contentNode in contentNodes" :key="contentNode.id" class="mb-5">
            <b-media>
              <b-img v-if="contentNode.image != null" slot="aside" :src="contentNode.image.url" height="150" width="150" rounded :alt="contentNode.title" />
              <b-img v-if="contentNode.image == null" slot="aside" src="https://media.graphcms.com/EVNPxU9MR2KI8cDo00T8" height="150" width="150" rounded :alt="contentNode.title" />
              <!-- <b-img v-if="contentNode.image == null" slot="aside" blank blank-color="#ccc" width="150" rounded alt="placeholder" /> -->
              <b-link :to="'/blog/' + contentNode.slug">
                <h4>{{ contentNode.title }}</h4>
              </b-link>
              <p><small class="text-muted" v-if="contentNode.contributors.firstName != null">by {{ contentNode.contributors.firstName }} | </small><small class="text-muted">{{ contentNode.publishDate | moment("MMMM DD") }}</small></p>
              <p class="card-text" v-if="contentNode.excerpt != null">{{ contentNode.excerpt }}</p>
              <p class="card-text" v-if="contentNode.excerpt == null">{{ contentNode.body | truncate(120) }}</p>
              <b-link :to="'/blog/' + contentNode.slug">Read More</b-link>
            </b-media>
          </b-col>
        </b-row>
      </b-container>
    </b-tab>
    <!-- <b-tab title="New Machines">
      Tab Contents 2
    </b-tab>
    <b-tab title="Closing Alerts">
      Tab Contents 2
    </b-tab>
    <b-tab title="Vacation Tips">
      Tab Contents 2
    </b-tab> -->
  </b-tabs>

  </div>
</template>

<script>
import gql from 'graphql-tag'

export const contentNodes = gql`
  query contentNodes {
    contentNodes( orderBy: publishDate_DESC, 
    where: {
        status: PUBLISHED,
        contentNodeType: BlogPost
    }) {
      id
      contentNodeType
      title
      excerpt
      image {
        url
      }
      contributors {
        firstName
        lastName
      }
      body
      slug
      publishDate
    }
  }
`

export default {
  name: 'News',
  metaInfo: {
    title: 'New Pressed Coins',
    meta: [
      { name: 'description', content: 'Treasured scoop about Walt Disney World’s best memory making souvenirs.' }
    ]
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  }
}
</script>
