<template>
  <div class="presscoins">
    <FeaturedJumbotron/>

    <!-- <SearchBar/> -->

    <b-container>
        <b-row>
          <b-col>
            <div v-for="contentNode in contentNodes" v-bind:key="contentNode.id">
              <h1>Presscoins <span class="pc-title-subheader">the Deffinitive Walt Disney World Pressed Coin Guide</span></h1>
              <vue-markdown v-if="contentNode.body != null">{{ contentNode.body }}</vue-markdown>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h2>Pressed Coin Locations</h2>
            <pc-locations/>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h2>New Pressed Coins</h2>
            <CoinsByFirstSeen />
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h2>Pressed Coin News</h2>
            <blogs/>
            <b-link to="/news" variant="link">See All Articles <font-awesome-icon :icon="['far', 'angle-right']" /></b-link>
          </b-col>
        </b-row>
        <!-- <b-row class="mb-4">
          <b-col>
            <h2>Presscoins in Social</h2>
            <vue-instagram token="3195053967.d42dea5.38cff8fdfd0b408b96f45053b9102511" :count="5" mediaType="image" class="mb-5">
              <template slot="feeds" slot-scope="props">
                <a :href="props.feed.link"><img :src="props.feed.images.low_resolution.url" alt="Image"></a>
              </template>
            </vue-instagram>
          </b-col>
        </b-row> -->
        <b-row class="mb-4" v-if="isAuthenticated">
          <b-col>
            <h2>Your Collection</h2>
            <UserCoinCollectionCarousel/>
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
import gql from 'graphql-tag'
// import VueInstagram from 'vue-instagram'
import { firebaseDb } from '@/plugins/firebaseConfig'
import FeaturedJumbotron from '@/components/FeaturedJumbotron'
import LocationsSnippet from '@/components/LocationsSnippet'
import BlogsSnippet from '@/components/BlogsSnippet'
import VueMarkdown from 'vue-markdown'
import CoinsByFirstSeen from '@/components/CoinsByFirstSeen'
import UserCoinCollectionCarousel from '@/components/UserCoinCollectionCarousel'

export const contentNodes = gql`
  query contentNodes {
    contentNodes(
    where: {
      slug: "home",
      status: PUBLISHED,
      contentNodeType: Page
    }) {
      title
      body
    }
  }
`

export default {
  name: 'Home',
  props: ['auth', 'authenticated'],
  metaInfo: {
    title: 'Presscoins',
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      { name: 'description', content: 'Presscoins.com features a comprehensive guide to Walt Disney World pressed coins, and also features a image searchable database of all pressed coins ever made.' },
      { name: 'keywords', content: 'unofficial disney elongated, souvenir penny and quarter, elongated penny machines, pressed coins, elongated coins, squashed coins, walt disney world, wdw coins, squished, smashed, elongated cents, pressed pennies, pressed cents, pressed quarter, pressed quarters, pressed penny' }
    ]
  },
  data: () => ({
    loading: 0,
    contentNodes: null,
    locations: []
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  },
  components: {
    VueMarkdown,
    // VueInstagram,
    'blogs': BlogsSnippet,
    'pc-locations': LocationsSnippet,
    FeaturedJumbotron,
    CoinsByFirstSeen,
    UserCoinCollectionCarousel
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated()
    },
    contentNodesTake2 () {
      return Array.isArray(this.contentNodes) ? this.contentNodes.slice(0, 2) : []
    }
  },
  firestore () {
    return {
      locations: firebaseDb.collection('locations').orderBy('createdAt')
    }
  }
}
</script>

<style lang="scss" scoped>
  .pc-title-subheader {
    font-size: 1rem;
    vertical-align: middle;
    opacity: 0.5;
    border-left: 4px solid rgba(#212529, 0.5);
    padding-left: 0.5rem;
    margin-left: 0.5rem;
  }

  .display-3 {
    color: #d52b6c;
  }

  .lead {
    color: #894b8a;
  }
</style>
