<template>
    <div class="pc-card-carousel">
        <carousel :autoplay="this.autoplay" :autoplayTimeout="5000" :autoplayHoverPause="true" navigationNextLabel="" navigationPrevLabel="" :navigationEnabled="true" :paginationEnabled="this.pagination" paginationColor="#b6c2db" paginationActiveColor="#296ef6" :perPageCustom="[[768, 3], [1024, 4]]" >
            <slot></slot>
        </carousel>
    </div>
</template>

<script>
export default {
  name: 'CardCarousel',
  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.pc-card-carousel {
    margin: -0.5rem -0.85rem 0rem -0.85rem;
}

@media(min-width:42rem) {
    .pc-card-carousel {
        margin: 0;
    }
}

.VueCarousel-slide {
    padding: 0.75rem 0.5rem;
}

.VueCarousel-navigation-button::before {
    border-color: #17213a;
    border-style: solid;
    border-width: 0.25rem 0.25rem 0 0;
    content: '';
    display: inline-block;
    height: 1rem;
    left: 0.15rem;
    position: relative;
    top: 0.15rem;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 1rem;
}

.VueCarousel-navigation-next:before {
    left: 0;
    transform: rotate(45deg);
}

.VueCarousel-navigation-prev:before {
    left: 0.25rem;
    transform: rotate(-135deg);
}
</style>
