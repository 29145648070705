<template>
  <div class="user-coin-collection">
    <b-container v-if="presscoins.length >= 75" class="p-0 mb-4">
      <b-row>
        <b-col>
          <b-card>
            <b-container>
              <b-row>
                <b-col class="text-center">
                  <!-- <img src="../assets/img/machine-waypoint.png" alt="404 Uh-oh" style="max-height: 10rem" /> -->
                  <h2>Upgrade to MyCollection+</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <ul style="list-style:none;">
                    <li><font-awesome-icon :icon="['far', 'check']" style="color:#4fa766" /> Unlimited coins per collection</li>
                    <li><font-awesome-icon :icon="['far', 'check']" style="color:#4fa766" /> Search and filter collection</li>
                    <li><font-awesome-icon :icon="['far', 'check']" style="color:#4fa766" /> Inventory tracking</li>
                    <li><font-awesome-icon :icon="['far', 'check']" style="color:#4fa766" /> Coin notes</li>
                    <li><font-awesome-icon :icon="['far', 'check']" style="color:#4fa766" /> Printable checklists</li>
                    <li><font-awesome-icon :icon="['far', 'check']" style="color:#4fa766" /> 10% off at the Presscoins shop</li>
                  </ul>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <hr>
                  <p style="color:#4fa766;font-size: 1.2rem">+ $4.95 / month</p>
                  <b-button variant="success" to="checkout" disabled="">Coming Soon</b-button>
                  <!-- <b-button variant="success" to="checkout">Upgrade</b-button> -->
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <pc-card-group v-if="presscoins.length >= 1">
      <div v-for="(p, index) in presscoins" :key="index" class="text-center">
        <b-link :to="'/pressed-coin/' + p.catalog" class="pc-coin pc-coin-own">
          <!-- <img src="../assets/img/presscoins-own-coin.png" alt="Own Coin Icon" class="pc-coin-own-icon"/> -->
          <b-img :src="'https://res.cloudinary.com/presscoins/image/upload/h_200,w_200,c_limit/d_contentNodes:presscoins-empty-coin-1.png/v1535414476/pressed-coins/' + p.catalog + '.jpg'" :alt="p.catalog" fluid></b-img>
          <RemoveFromCollectionBtn :catalog="p.catalog" />
          <!-- v-if="isRoleOnUser('plus')" -->
          <AddCoinNoteBtn :catalog="p.catalog" v-if="isRoleOnUser('plus')" />
          <AddCoinQtyBtn :catalog="p.catalog" v-if="isRoleOnUser('plus')" />
          <AddCoinFavBtn :catalog="p.catalog" />
        </b-link>
      </div>
    </pc-card-group>

    <b-card class="text-center" v-if="presscoins.length == 0">
      <!-- <img src="../assets/img/machine-waypoint.png" alt="Happy Penny" style="max-height: 10rem" /> -->
      <h2>Eureka...</h2>
      <p>You found it! Enjoy adding your special, memory making pressed coins to your collection.
      <br>To add coins, find and click "Got It" on the coins you have.</p>
      <b-button to="search" variant="primary">Start Your Collection</b-button>
    </b-card>
  </div>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig'
// import CardCarousel from '@/components/CardCarousel'
import CardGroup from '@/components/CardGroup'
import RemoveFromCollectionBtn from '@/components/RemoveFromCollectionBtn'
import AddCoinNoteBtn from '@/components/AddCoinNoteBtn'
import AddCoinQtyBtn from '@/components/AddCoinQtyBtn'
import AddCoinFavBtn from '@/components/AddCoinFavBtn'
import { mapGetters } from 'vuex'

export default {
  name: 'UserCoinCollection',
  props: {
    limit: {
      type: Number,
      default: 24
    }
  },
  data () {
    return {
      presscoins: [] // Empty array to populate from firestore
    }
  },
  computed: {
    ...mapGetters([
      'isRoleOnUser'
    ])
  },
  methods: {
    getPresscoins: function () {
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .orderBy('date_added', 'desc')
        .limit(this.limit)
        .onSnapshot(querySnapshot => {
          let presscoinsArray = []

          querySnapshot.forEach(doc => {
            let coin = doc.data()
            coin.id = doc.id
            presscoinsArray.push(coin)
          })

          this.presscoins = presscoinsArray
        })
    }
  },
  beforeMount () {
    this.getPresscoins()
  },
  components: {
    // 'pc-card-carousel': CardCarousel,
    'pc-card-group': CardGroup,
    RemoveFromCollectionBtn,
    AddCoinNoteBtn,
    AddCoinQtyBtn,
    AddCoinFavBtn
  }
}
</script>
