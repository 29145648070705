<template>
  <div class="coin">
    <b-container class="mt-3 mt-md-5" v-for="(presscoin, index) in presscoins" :key="index">
        <b-row class="mb-5 align-items-center">
          <b-col sm="12" md="7" class="text-center">
            <b-link v-b-modal.modal-coin class="pc-coin pc-coin--large">
              <b-img :src="'https://res.cloudinary.com/presscoins/image/upload/h_350,w_350,c_limit,q_auto/d_contentNodes:presscoins-empty-coin-1.png/c_scale,g_south_west,l_contentNodes:presscoins-classic-logo-dark,w_150,x_10,y_25/v1535414476/pressed-coins/' + presscoin.catalog + '.jpg'" :alt="presscoin.catalog" fluid></b-img>
              <AddCoinFavBtn :catalog="presscoin.catalog" size="large" />
            </b-link>

            <b-modal id="modal-coin" centered lazy hide-footer hide-header>
              <div class="text-center">
                <b-img-lazy :src="'https://res.cloudinary.com/presscoins/image/upload/d_contentNodes:presscoins-empty-coin-1.png/c_scale,g_south_west,l_contentNodes:presscoins-classic-logo-dark,w_150,x_10,y_25/v1535414476/pressed-coins/' + presscoin.catalog + '.jpg'" :alt="presscoin.catalog" fluid></b-img-lazy>
              </div>
            </b-modal>
          </b-col>
          <b-col sm="12" md="5">
            <b-card v-if="isRoleOnUser('admin') && presscoin.verified && presscoin.available == 'True'" class="mb-4">
              <small>Last Verified </small> <b-badge variant="warning">Admin Only</b-badge>
              <p>{{ presscoin.verified | moment("dddd, MMMM Do YYYY") }} ({{ presscoin.verified | moment("from", "now", true) }} ago)</p>
              <b-button variant="primary" class="mb-2" @click="verifyCoin()" block><font-awesome-icon :icon="['far', 'check']" /> Check-off</b-button>
            </b-card>

            <h1 class="mb-1">{{presscoin.shortdesc}} Pressed <span v-if="presscoin.coin == 'Cent'">Penny</span> <span v-if="presscoin.coin != 'Cent'">{{presscoin.coin}}</span></h1>
            <p><small><span v-if="presscoin.place">{{presscoin.place}}</span><span v-if="presscoin.area"> | {{presscoin.area}} </span> <span v-if="presscoin.location"> | <b-link :to="'/machine/' + presscoin.machine">{{presscoin.location}}</b-link></span></small></p>

            <p v-if="presscoin.description">{{presscoin.description}}</p>

            <b-container class="p-0">
              <b-row>
                <b-col sm="6">
                  <small>Catalog Number <b-link to="/page/disney-pressed-coin-catalog-numbers" v-b-tooltip.hover title="Info"><font-awesome-icon :icon="['far', 'info-circle']" /></b-link></small>
                  <p v-if="presscoin.catalog">{{presscoin.catalog}} <b-badge variant="primary" v-if="presscoin.available == 'True'">Available</b-badge> <b-badge variant="secondary" v-if="presscoin.available == 'False'">Retired</b-badge></p>
                </b-col>
                <b-col sm="6" v-if="presscoin.newcatalog && presscoin.catalog != presscoin.newcatalog">
                  <small>New Catalog Number <b-link to="/page/disney-pressed-coin-catalog-numbers" v-b-tooltip.hover title="Info"><font-awesome-icon :icon="['far', 'info-circle']" /></b-link></small>
                  <p>{{presscoin.newcatalog}} <b-badge variant="primary" v-if="presscoin.available == 'True'">Available</b-badge> <b-badge variant="secondary" v-if="presscoin.available == 'False'">Retired</b-badge></p>
                </b-col>
                <b-col sm="6">
                  <div v-if="presscoin.machine_type">
                    <small>Machine Type </small>
                    <p>{{ presscoin.machine_type }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-container>

            <div>
              <add-to-collection-btn :catalog="presscoin.catalog" :title="presscoin.shortdesc" :coinType="presscoin.coin" />
              <!-- <b-button @click="makeToast()" variant="outline-primary" block class="mt-2"><font-awesome-icon :icon="['far', 'stars']" /> Add to Want List</b-button> -->
            </div>

            <social-sharing :url="'https://beta.presscoins.com/pressed-coin/' + presscoin.catalog"
                      :title="presscoin.shortdesc"
                      :description="presscoin.description"
                      :media="'https://res.cloudinary.com/presscoins/image/upload/d_contentNodes:presscoins-empty-coin-1.png/c_scale,g_south_west,l_contentNodes:presscoins-classic-logo-dark,w_150,x_10,y_25/v1535414476/pressed-coins/' + presscoin.catalog + '.jpg'"
                      hashtags="presscoins"
                      twitter-user="presscoins"
                      inline-template>
              <div class="mt-4 mb-2">
                <network network="facebook">
                  <b-badge pill variant="primary" class="px-3 py-2 b-facebook mr-1"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/> Facebook</b-badge>
                </network>
                <network network="pinterest">
                  <b-badge pill variant="primary" class="px-3 py-2 b-pinterest mr-1"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'pinterest' }"/> Pinterest</b-badge>
                </network>
                <network network="twitter">
                  <b-badge pill variant="primary" class="px-3 py-2 b-twitter"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/> Twitter</b-badge>
                </network>
              </div>
            </social-sharing>

          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <section class="presscoins-series mb-4" v-if="presscoin.series">
              <h4 class="mb-0">More {{presscoin.series}} Series Pressed <span v-if="presscoin.coin == 'Cent'">Pennies</span> <span v-if="presscoin.coin != 'Cent'">{{presscoin.coin}}s</span></h4>
              <p class="mb-0">Below you can see other coins from the {{presscoin.series}} series.</p>
              <coins-by-series :series="presscoin.series" />
            </section>

            <section class="presscoins-variations mb-4" v-if="presscoin.variation">
              <h4 class="mb-0">Other Variations of the {{presscoin.variation}} Pressed <span v-if="presscoin.coin == 'Cent'">Penny</span> <span v-if="presscoin.coin != 'Cent'">{{presscoin.coin}}</span></h4>
              <p class="mb-0">Below you can see other {{presscoin.series}} variations.</p>
              <coins-by-variation :variation="presscoin.variation" />
            </section>

            <section class="presscoins-machines mb-4" v-if="presscoin.machine">
              <h4 class="mb-0">More Pressed <span v-if="presscoin.coin == 'Cent'">Pennies</span> <span v-if="presscoin.coin != 'Cent'">{{presscoin.coin}}s</span> from this Machine</h4>
              <p class="mb-0">These are the pressed <span v-if="presscoin.coin == 'Cent'">pennies</span> <span v-if="presscoin.coin != 'Cent'">{{presscoin.coin}}s</span> that are currently available at {{presscoin.location}}.</p>
              <coins-by-machine :machine="presscoin.machine" />
            </section>

            <section class="presscoins-map mb-4" v-if="presscoin.latitude">
              <h4 class="mb-0">Find this Pressed <span v-if="presscoin.coin == 'Cent'">Penny</span> <span v-if="presscoin.coin != 'Cent'">{{presscoin.coin}}s</span></h4>
              <p>You can find these coin at {{ presscoin.location }} in {{ presscoin.place }}.<br>To see more coin press machines in this area visit our <b-link to="/map">Pressed Coin Map</b-link>.</p>
              <GmapMap
              :center="{lat:presscoin.latitude, lng:presscoin.longitude}"
              :zoom="18"
              :options='{
                mapTypeControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: [
                          {
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#8ec3b9"
                              }
                            ]
                          },
                          {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1a3646"
                              }
                            ]
                          },
                          {
                            "featureType": "administrative.country",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#4b6878"
                              }
                            ]
                          },
                          {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#64779e"
                              }
                            ]
                          },
                          {
                            "featureType": "administrative.province",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#4b6878"
                              }
                            ]
                          },
                          {
                            "featureType": "landscape.man_made",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#334e87"
                              }
                            ]
                          },
                          {
                            "featureType": "landscape.natural",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#023e58"
                              }
                            ]
                          },
                          {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#283d6a"
                              }
                            ]
                          },
                          {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#6f9ba5"
                              }
                            ]
                          },
                          {
                            "featureType": "poi",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [
                              {
                                "color": "#023e58"
                              }
                            ]
                          },
                          {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#3C7680"
                              }
                            ]
                          },
                          {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#304a7d"
                              }
                            ]
                          },
                          {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#98a5be"
                              }
                            ]
                          },
                          {
                            "featureType": "road",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#2c6675"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                              {
                                "color": "#255763"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#b0d5ce"
                              }
                            ]
                          },
                          {
                            "featureType": "road.highway",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#023e58"
                              }
                            ]
                          },
                          {
                            "featureType": "transit",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#98a5be"
                              }
                            ]
                          },
                          {
                            "featureType": "transit",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                              {
                                "color": "#1d2c4d"
                              }
                            ]
                          },
                          {
                            "featureType": "transit.line",
                            "elementType": "geometry.fill",
                            "stylers": [
                              {
                                "color": "#283d6a"
                              }
                            ]
                          },
                          {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#3a4762"
                              }
                            ]
                          },
                          {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                              {
                                "color": "#0e1626"
                              }
                            ]
                          },
                          {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                              {
                                "color": "#4e6d70"
                              }
                            ]
                          }
                        ]}'
              style="width: 100%; height: 400px"
            >
              <GmapMarker
                :position="{lat:presscoin.latitude, lng:presscoin.longitude}"
                :clickable="true"
                :icon="{url : require('../assets/img/machine-marker.png')}"
                @click="openInfoWindowTemplate()"
              />
            <gmap-info-window
                :options="{maxWidth: 300}"
                :position="{lat:presscoin.latitude, lng:presscoin.longitude}"
                :opened="infoWindow.open"
                @closeclick="infoWindow.open=false">
                {{presscoin.location}}
            </gmap-info-window>
            </GmapMap>
            </section>
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
import { presscoinsCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig
import CoinsByVariation from '@/components/CoinsByVariation'
import CoinsBySeries from '@/components/CoinsBySeries'
import CoinsByMachine from '@/components/CoinsByMachine'
import AddToCollectionBtn from '@/components/AddToCollectionBtn'
import AddCoinFavBtn from '@/components/AddCoinFavBtn'
import { mapGetters } from 'vuex'

export default {
  name: 'CoinOne',
  metaInfo () {
    return {
      title: this.presscoins[0].shortdesc + ' Pressed Coin',
      meta: [
        { name: 'description', content: this.presscoins[0].description + ' pressed coin' }
      ]
    }
  },
  data () {
    return {
      // currentLocation: { lat: 0, lng: 0 },
      infoWindow: {
        open: false
      },
      presscoins: [] // Empty array to populate from firestore
    }
  },
  // computed: {
  //   presscoin () {
  //     return this.$store.getters.getPresscoinById(this.coin_catalogid)
  //   }
  // },
  // mounted: function () {
  //   this.geolocation()
  // },
  computed: {
    ...mapGetters([
      'isRoleOnUser'
    ])
  },
  methods: {
    getPresscoins: function () {
      presscoinsCollection.where('catalog', '==', this.$route.params.slug).limit(1).onSnapshot(querySnapshot => {
        let presscoinsArray = []

        querySnapshot.forEach(doc => {
          let coin = doc.data()
          coin.id = doc.id
          presscoinsArray.push(coin)
        })

        this.presscoins = presscoinsArray
      })
    },
    openInfoWindowTemplate: function () {
      this.infoWindow.open = true
    },
    verifyCoin (variant = null) {
      this.$bvToast.toast('Thank you for verifing this coin.', {
        title: 'Coin Verified!',
        variant: variant,
        solid: false
      })
    }
    // geolocation: function () {
    //   console.log('fire')
    //   navigator.geolocation.getCurrentPosition(function (position) {
    //       console.log('stuff')
    //       this.currentLocation = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude
    //       }
    //     })
    // .catch(err => {
    //   // Error log
    //   console.log('Error mapping user:', err.message)
    // })
    // }
  },
  beforeMount () {
    this.getPresscoins()
  },
  components: {
    CoinsByVariation,
    CoinsBySeries,
    CoinsByMachine,
    AddToCollectionBtn,
    AddCoinFavBtn
  }
}
</script>
