<template>
   <footer class="bg-primary">
    <b-container class="bg-primary text-white p-4">
      <b-row>
        <!-- <b-col cols="6" lg="3" class="mb-2 mb-md-0">
          <b-link to="/shop" class="text-white"><h6>Shop</h6></b-link>
          <b-link to="/shop" class="text-white"><small>Pressed Coin Book</small></b-link><br>
          <b-link to="/shop" class="text-white"><small>Pressed Coin Checklists & Maps</small></b-link><br>
          <b-link to="/shop" class="text-white"><small>Pressed Coin Accessories</small></b-link>
        </b-col> -->
        <b-col cols="6" lg="3" class="mb-4 mb-md-0">
          <b-link to="/listings" class="text-white"><h6>Listings</h6></b-link>
          <b-link to="/listings" class="text-white"><small>Current Pressed Coin Listings</small></b-link><br>
          <b-link to="/listings" class="text-white"><small>Previous Pressed Coin Listings</small></b-link>
        </b-col>
        <b-col cols="6" lg="3" class="mb-4 mb-md-0">
          <b-link to="/news" class="text-white"><h6>News</h6></b-link>
          <b-link to="/news" class="text-white"><small>Pressed Coin News (Blog)</small></b-link><br>
          <b-link to="/news" class="text-white"><small>Latest Pressed Coins</small></b-link>
        </b-col>
        <b-col cols="6" lg="3" class="mb-4 mb-md-0">
          <b-link to="/page/about" class="text-white"><h6>About</h6></b-link>
          <b-link to="/page/about" class="text-white"><small>About Us</small></b-link><br>
          <b-link to="/page/disney-pressed-coin-catalog-numbers" class="text-white"><small>Pressed Coin Catalog Numbers</small></b-link><br/>
          <b-link to="/faq" class="text-white"><small>FAQ</small></b-link><br>
          <!-- <b-link to="/site-map" class="text-white"><small>Site Map</small></b-link> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center text-md-left">
          <hr>
          <b-link to="/"><img src="../assets/img/presscoins-logo.svg" alt="Presscoins Logo" class="justify-content-center mb-md-4" style="max-width:12rem;margin:0 auto;display:block;"></b-link>
          <h6>Follow Us</h6>
          <b-link href="https://www.facebook.com/presscoins/" target="_blank" class="p-2 text-white"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/></b-link>
          <b-link href="https://www.twitter.com/presscoins/" target="_blank" class="p-2 text-white"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/></b-link>
          <b-link href="https://www.instagram.com/presscoins/" target="_blank" class="p-2 text-white"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"/></b-link>
          <b-link href="https://www.pinterest.com/presscoins/" target="_blank" class="p-2 text-white"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'pinterest' }"/></b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-block d-md-flex justify-content-between align-items-center text-center text-md-left">
          <small class="text-center text-md-left">Copyright © 2002-{{ new Date().getFullYear() }} Presscoins <br> All Rights Reserved.</small>

          <div class="text-center text-md-left">
            <b-link to="/page/privacy-policy" class="text-white mr-2"><small>Privacy Policy</small></b-link>&nbsp;|&nbsp;&nbsp;<b-link to="/page/terms-of-service" class="text-white"><small>Terms of Service</small></b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {
  name: 'footerNav'
}
</script>
