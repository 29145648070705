<template>
  <div class="account">
    <b-jumbotron
      style="background:url(https://res.cloudinary.com/presscoins/image/upload/v1568165907/contentNodes/presscoins-walt-disney-world-pressed-coin-account.jpg) no-repeat center / cover"
      header="Your Account"
      :lead="'Hello, '+ newDisplayName + '! Update your profile and account settings.'"
      class="text-white"
      fluid>
      <b-button id="qsLogoutBtn" to="/logout" variant="light"><font-awesome-icon :icon="['far', 'sign-out']" /> Logout</b-button>
    </b-jumbotron>

    <b-container>
        <b-row>
          <b-col>
            <section class="section-account-details mb-5">
              <h2>Settings & Details</h2>
              <p><strong>{{userEmail}}</strong> <b-link class="float-right" v-b-modal.change-email>Change Email</b-link></p>
              <b-modal id="change-email" centered title="Change Email">
                <label for="input-email">Email</label>
                <b-form-input v-model="email" id="input-email" aria-describedby="input-email-help input-email-feedback" maxlength="50" type="email" class="mb-2" :state="errorEmail == ''" placeholder="mickey@presscoins.com"></b-form-input>
                <b-form-invalid-feedback id="input-email-feedback">
                  {{ errorEmail }}
                </b-form-invalid-feedback>
                <b-form-text id="input-email-help">Your new email address</b-form-text>
                <!-- <label>Re-type email</label> -->
                <!-- <b-form-input maxlength="50" type="email" class="mb-2" placeholder="Confirm your new email"></b-form-input> -->
                <template slot="modal-footer">
                  <b-button variant="white" @click="$bvModal.hide('change-email')">Cancel</b-button>
                  <b-button variant="primary" @click="updateEmail()">Save</b-button>
                </template>
              </b-modal>
              <p>Password: <strong class="text-muted">********</strong> <b-link class="float-right" v-b-modal.change-password>Change My Password</b-link></p>
              <b-modal id="change-password" centered title="Change My Password">
                <label for="input-password">Password</label>
                <b-form-input v-model="password" maxlength="50" aria-describedby="input-password-help input-password-feedback" id="input-password" type="password" class="mb-2" :state="errorPassword == ''" placeholder="Enter your new password"></b-form-input>
                <b-form-invalid-feedback id="input-password-feedback">
                  {{ errorPassword }}
                </b-form-invalid-feedback>
                <b-form-text id="input-password-help">Your new password</b-form-text>
                <!-- <label>Re-type password</label> -->
                <!-- <b-form-input maxlength="50" type="password" class="mb-2" placeholder="Confirm your new password"></b-form-input> -->
                <template slot="modal-footer">
                  <b-button variant="white" @click="$bvModal.hide('change-password')">Cancel</b-button>
                  <b-button variant="primary" @click="updatePassword()">Save</b-button>
                </template>
              </b-modal>
              <hr/>
              <p>Name: <strong>{{newDisplayName}}</strong> <b-link class="float-right" v-b-modal.change-details>Change Details</b-link></p>
              <b-modal id="change-details" centered title="Change Details">
                <label for="input-profile">First name</label>
                <b-form-input v-model="newDisplayName" id="input-profile" maxlength="25" aria-describedby="input-profile-feedback" type="text" class="mb-2" placeholder="Mickey"></b-form-input>
                <b-form-invalid-feedback id="input-profile-feedback">
                  {{ errorProfile }}
                </b-form-invalid-feedback>
                <template slot="modal-footer">
                  <b-button variant="white" @click="$bvModal.hide('change-details')">Cancel</b-button>
                  <b-button variant="primary" @click="updateProfile()">Save</b-button>
                </template>
              </b-modal>
              <p>Started using Presscoins: <strong>{{ userStarted | moment("MMMM YYYY") }} ({{ userStarted | moment("from", "now", true) }})</strong></p>
              <p>Account type: <srong>{{userAccountType}}</srong> <!-- {{userAdmin}} --> <!--<b-link class="float-right" to="upgrade">Upgrade to MyCollection+</b-link>--> </p>
              <b-link class="text-danger" v-b-modal.delete-account>Delete Account</b-link>
              <b-modal id="delete-account" centered title="Are you sure you want to delete your account?">
                <p>Sorry to see you go so soon. Things change and that's okay! Once deleted though, all information related to it (including all coins, favorites, lists, collection items, personal data, etc.) will be <em> irretrievable</em>.</p>
                <label>Please type DELETE</label>
                <b-form-input v-model="confirmDelete" type="password" maxlength="25" class="mb-2" placeholder="Confirm account deletion"></b-form-input>
                <template slot="modal-footer">
                  <b-button variant="white" @click="$bvModal.hide('delete-account')">Cancel</b-button>
                  <b-button variant="danger" @click="deleteUser()" :disabled="confirmDelete != 'delete' && confirmDelete != 'DELETE' && confirmDelete != 'Delete'">Yes, delete account</b-button>
                </template>
              </b-modal>
            </section>

            <!-- <hr/> -->

            <!-- <section class="section-linked-accounts my-5">
              <h2>Linked Accounts</h2>
              <p><img src="../assets/img/google-logo.png" alt="Google Logo" class="linked-accounts"> Google linked as <strong>{{displayName}}</strong ><b-link class="float-right"><font-awesome-icon :icon="['far', 'unlink']" /> Unlink</b-link></p>
              <p><img src="../assets/img/facebook-logo.png" alt="Google Logo" class="linked-accounts is-inactive"> <span class="text-muted"> Facebook</span> <b-link class="float-right"><font-awesome-icon :icon="['far', 'link']" /> Link</b-link></p>
            </section> -->

            <hr/>

            <section class="section-support my-5">
              <h2><font-awesome-icon :icon="['far', 'life-ring']" /> Support</h2>
              <p>Need help? We put together <b-link to="/faq">answers to commonly asked questions</b-link> and <b-link to="/faq">troubleshooting guides</b-link> to help.<br/>If that doesn't do the trick, get in touch. We're here to help! <b-link href="mailto:support@presscoins.com">Contact Support</b-link></p>
            </section>
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>
<script>
// import { firebaseAuth } from '@/plugins/firebaseConfig'

export default {
  name: 'Account',
  metaInfo: {
    title: 'Account',
    meta: [
      { name: 'description', content: 'Your Presscoins Account Profile' }
    ]
  },
  data () {
    return {
      confirmDelete: '',
      newDisplayName: this.$store.state.user.displayName ? this.$store.state.user.displayName : 'Presscoins fan',
      email: this.$store.state.user.email,
      userAccountType: 'Free',
      errorEmail: '',
      errorPassword: '',
      errorProfile: ''
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated()
    },
    displayName () {
      return this.$store.state.user ? this.$store.state.user.displayName : ''
    },
    userPhoto () {
      return this.$store.state.user ? this.$store.state.user.photoURL : ''
    },
    userEmail () {
      return this.$store.state.user ? this.$store.state.user.email : ''
    },
    userStarted () {
      return this.$store.state.user ? this.$store.state.user.metadata.creationTime : ''
    }
    // userAccountType () {
    //   return this.$store.state.user ? this.$store.state.user.accountType : ''
    // }
    // userAdmin () {
    //   return this.$store.state.user.admin ? this.$store.state.user.admin : ''
    // if (firebaseAuth.data.role_id === 'ADMIN') {
    //   return true
    // } else {
    //   return false
    // }
  // }
  },
  methods: {
    deleteUser () {
      this.$store.state.user.delete()
        .then(result => {
          // Deleted user
          console.log('User deleted successfully')
          this.$router.replace('/home')
        })
        .catch(err => {
          // Error log
          console.log('Error deleting user:', err.message)
        })
    },
    // passwordResetEmail () {
    //   this.$store.state.user.sendPasswordResetEmail(this.$store.state.email)
    //     .then(result => {
    //       // Sent user password reset email
    //       console.log('Password reset email sent successfully')
    //       // make toast
    //       this.$bvToast.toast('Password reset email sent successfully')
    //     })
    //     .catch(err => {
    //       // Error log
    //       console.log('Error sending password reset email:', err.message)
    //     })
    // },
    updateProfile () {
      var _this = this
      this.$store.state.user.updateProfile({
        displayName: this.newDisplayName
        // photoURL: "https://example.com/jane-q-user/profile.jpg"
      }).then(function () {
        // Update successful.
        console.log('Updated profile successfully')
        // Close modal
        _this.$bvModal.hide('change-details')
      }).catch(function (err) {
        // An error happened.
        console.log('Error updating profile:', err.message)
        _this.errorProfile = err.message
      })
    },
    updateEmail () {
      var _this = this
      this.$store.state.user.updateEmail(this.email).then(function () {
        // Update successful.
        console.log('Updated email successfully')
        // Close modal
        _this.$bvModal.hide('change-email')
      }).catch(function (err) {
        // An error happened.
        console.log('Error updating email:', err.message)
        // Display error message
        _this.errorEmail = err.message
      })
    },
    updatePassword () {
      var _this = this
      this.$store.state.user.updatePassword(this.password).then(function () {
        // Update successful.
        console.log('Updated password successfully')
        // Close modal
        _this.$bvModal.hide('change-password')
      }).catch(function (err) {
        // An error happened.
        console.log('Error updating password:', err.message)
        // Display error message
        _this.errorPassword = err.message
      })
    }
  }
}
</script>
