<template>
  <div>
    <b-link @click.stop="removeFromCollection(this.catalog)" v-b-tooltip.hover title="Add Note" style="position: absolute;left: 1rem;bottom: 0.75rem;">
      <font-awesome-icon :icon="['far', 'comment-alt-plus']" />
    </b-link>
     <!-- <b-link v-if="!p.note" class="pc-coin-note" @click="addCoinNote(p.catalog)" v-b-tooltip.hover title="Add Note" style="position: absolute;left: 1rem;bottom: 0.75rem;"><font-awesome-icon :icon="['far', 'comment-alt-plus']" /></b-link>
      <b-link v-if="p.note" class="pc-coin-note" @click="addCoinNote(p.catalog)" v-b-tooltip.hover title="View Note" style="position: absolute;left: 1rem;bottom: 0.75rem;"><font-awesome-icon :icon="['far', 'comment-alt-lines']" /></b-link>
      <b-modal :id="'coin-note-' + p.catalog" title="Coin Note" centered>
      <b-textarea maxlength="580" :value="p.note"></b-textarea>
      </b-modal> -->
  </div>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig

export default {
  name: 'AddCoinNoteBtn',
  props: {
    catalog: {
      type: String,
      default: 'MAAK0018'
    }
  },
  methods: {
    removeFromCollection (id) {
      // userCollection.doc(state.user.uid).collection('userCoinCollection').add({
      //   catalog: model.accountValue,
      //   quantity: '1'
      // })
      // .doc("DC")
      userCollection.doc(this.$store.state.user.uid).collection('userCoinCollection').doc(id).delete().then(function () {
        console.log('Coin successfully deleted!')
        this.$bvToast.toast(`Coin removed successfully.`, {
          title: 'Coin Removed',
          autoHideDelay: 5000
        })
      }).catch(function (error) {
        console.error('Error removing coin: ', error)
      })
    }
  }
}
</script>
