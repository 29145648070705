<template>
  <b-container fluid>
    <b-row align-v="center" style="min-height:78vh;background:url(https://res.cloudinary.com/presscoins/image/upload/v1568167219/contentNodes/login-bg.jpg) top/cover;">
      <b-col cols="12" md="6">
        <b-card class="sign-up text-center mt-5 mb-5" style="max-width: 25rem;margin:0 auto">
          <h1>Sign up</h1>
          <social-login/>
          <p class="my-2 text-muted">or sign up with email</p>
          <b-form-input
            type="text"
            v-model="displayName"
            placeholder="Name"
            maxlength="50"
            class="mb-2"
            required/>

          <b-form-input
            type="email"
            v-model="email"
            placeholder="Email"
            maxlength="50"
            class="mb-2"
            required/>

          <b-form-input
            type="password"
            v-model="password"
            placeholder="Password"
            maxlength="50"
            class="mb-4"
            required/>

          <b-button  :disabled="loading" v-on:click="signUp" block variant="primary" class="mb-2">Sign up</b-button>

          <p class="text-danger" v-if="error.show">
            {{error.msg}}
          </p>

          <p class="mb-0">Already have a Presscoins Account?
            <b-link to="/login">Log in</b-link>
          </p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import SocialLogin from '@/components/SocialLogin'
import { firebaseAuth, userCollection } from '@/plugins/firebaseConfig'

export default {
  name: 'signup',
  metaInfo: {
    title: 'Sign Up',
    meta: [
      { name: 'description', content: 'Signup to your Presscoins account to start your Walt Disney World pressed coin collection today!' }
    ]
  },
  data () {
    return {
      displayName: '',
      email: '',
      password: '',
      error: {
        show: false,
        msg: ''
      },
      loading: false
    }
  },
  mounted () {
    // if already signed in, send to dashboard.
    if (this.$store.getters.isAuthenticated()) {
      this.$router.push('/home')
    }
  },
  methods: {
    async signUp () {
      try {
        this.loading = true
        // TODO: add some validation and feedback with vee-validate
        // https://firebase.google.com/docs/reference/js/firebase.auth.html#usercredential
        const userCredential = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password)

        console.log('createUserWithEmailAndPassword result: ', userCredential)
        const userId = userCredential.user.uid

        // because the user signed up we know this is the first time seeing them and we can set their user doc.
        userCollection.doc(userId).set({
          // Default user document, be sure matches social login
          email: this.email,
          displayName: this.displayName,
          userId: userId,
          roles: []
        }).then(() => {
          this.$router.push('/home')
        }).catch(err => {
          console.error(err)
          this.error.msg = err.message
          this.error.show = false
        })
      } catch (err) {
        console.error(err)
        this.error.msg = err.message
        this.error.show = false
      }
    }
  },
  components: {
    'social-login': SocialLogin
  }
}
</script>
