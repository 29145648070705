var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pressed-coin-map text-center"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"prod_PRESSCOINS_MAP"}},[_c('ais-configure',{attrs:{"filters":'available:True',"aroundLatLngViaIP":true,"getRankingInfo":true}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12","md":"8","order-md":"2"}},[_c('app-map')],1),_c('b-col',{staticClass:"pc-map__search-panel p-0",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"pc-map__search-tools"},[_c('div',{staticClass:"pc-search-box"},[_c('b-input-group',{staticClass:"pc-search__input-group"},[_c('ais-search-box',{staticClass:"d-block",attrs:{"show-loading-indicator":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var isSearchStalled = ref.isSearchStalled;
var refine = ref.refine;
return _c('input',{staticClass:"form-control d-block",staticStyle:{"border-radius":"6rem 0 0 6rem"},attrs:{"type":"search","placeholder":"Try \"Tomorrowland\" or \"Peter Pan\"","autocomplete":"off"},domProps:{"value":currentRefinement},on:{"input":function($event){return refine($event.currentTarget.value)}}})}}])},[_c('span',{attrs:{"hidden":!_vm.isSearchStalled}},[_vm._v("Loading...")])]),_c('b-input-group-append',[_c('b-button',{staticClass:"py-0 px-3",attrs:{"variant":"primary"}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['far', 'search']}})],1)],1)],1)],1),_c('ais-refinement-list',{staticClass:"pc-map__refinement-list",attrs:{"attribute":"place","limit":5,"class-names":{
              'ais-RefinementList-checkbox': 'd-none',
              'ais-RefinementList-label': 'pc-map__labl',
              'ais-RefinementList-labelText': 'pc-map__label-text',
              'ais-RefinementList-count': 'd-none badge badge-secondary',
              'ais-RefinementList-list': 'pc-map__list',
              'ais-RefinementList-item': 'pc-map__list-item',
              'ais-RefinementList-item--selected': 'pc-map__list-item--selected'
            }}})],1),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var query = ref.query;
            var hits = ref.hits;
return [(hits.length)?_c('div',[_c('ais-infinite-hits',{staticClass:"mb-2",attrs:{"class-names":{
                    'ais-InfiniteHits-loadMore': 'btn btn-primary mt-2 mx-auto',
                    'ais-InfiniteHits-item': 'list-group-item list-group-item-action text-left',
                    'ais-InfiniteHits-loadMore--disabled': 'd-none'
                  }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return _c('router-link',{staticClass:"pc-search__item",attrs:{"to":'/machine/' + item.machine}},[_c('b-container',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"2"}},[_c('b-img',{attrs:{"src":'https://res.cloudinary.com/presscoins/image/upload/d_contentNodes:presscoins-default.png/c_fill,h_100,q_auto,w_100/v1535414476/machines/' + item.machine + '.jpg',"rounded":"circle","blank-color":"#777","alt":item.machine,"fluid":""}})],1),_c('b-col',{staticClass:"pr-0",attrs:{"cols":"10"}},[_c('small',{staticClass:"d-block"},[_vm._v(_vm._s(item.place))]),_c('h5',{staticClass:"mb-1 d-inline-block pc-search_title"},[_c('add-to-collection-btn',{staticClass:"d-inline-block mr-1",attrs:{"catalog":item.catalog,"title":item.shortesc,"coinType":item.coin,"buttonSmall":true}}),_vm._v(_vm._s(item.location))],1),(item._rankingInfo.geoDistance <= 2000000)?_c('small',{staticClass:"d-block text-muted"},[_vm._v(_vm._s(_vm._f("numFormat")(_vm._f("units")(item._rankingInfo.geoDistance,'m', 'mi', false),'0.0'))+" mi. away")]):_vm._e()])],1)],1)],1)}}],null,true)})],1):_vm._e(),(!hits.length)?_c('div',{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"100%","max-width":"10rem"},attrs:{"src":require("../assets/img/machine-waypoint.png"),"alt":"404 Uh-oh"}}),_c('h2',[_vm._v("No pressed coins found for "),_c('i',[_vm._v(_vm._s(query))]),_vm._v(".")])]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }