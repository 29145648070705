<template>
<div>
  <b-container class="p-0">
    <b-row>
      <b-col lg="6" v-for="contentNode in contentNodes" :key="contentNode.id" class="mb-4">
        <b-media>
          <b-img v-if="contentNode.image != null" slot="aside" :src="contentNode.image.url" height="100" width="100" rounded :alt="contentNode.title" />
          <b-img v-if="contentNode.image == null" slot="aside" src="https://media.graphcms.com/EVNPxU9MR2KI8cDo00T8" height="100" width="100" rounded :alt="contentNode.title" />
          <!-- <b-img v-if="contentNode.image == null" slot="aside" blank blank-color="#ccc" width="100" rounded alt="placeholder" /> -->
          <b-link :to="'/blog/' + contentNode.slug">
            <h5>{{ contentNode.title }}</h5>
          </b-link>
          <p class="card-text" v-if="contentNode.excerpt != null">{{ contentNode.excerpt }} <b-link :to="'/blog/' + contentNode.slug">Read More</b-link></p>
          <p class="card-text" v-if="contentNode.excerpt == null">{{ contentNode.body | truncate(120) }} <b-link :to="'/blog/' + contentNode.slug">Read More</b-link></p>
        </b-media>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import gql from 'graphql-tag'

export const contentNodes = gql`
  query contentNodes {
    contentNodes( first:2,
    orderBy: publishDate_DESC, 
    where: {
        status: PUBLISHED,
        contentNodeType: BlogPost
    }) {
      id
      contentNodeType
      title
      keywords {
        slug
      }
      image {
        url
      }
      excerpt
      body
      slug
      publishDate
      headerImage {
        url
      }
    }
  }
`

export default {
  name: 'Blogs',
  props: {
    keywords: {
      type: String,
      default: 'magic-kingdom'
    }
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  }
}
</script>
