<template>
  <div class="user-coin-collection">
    <pc-card-carousel v-if="presscoins.length >= 1">
      <slide v-for="(p, index) in presscoins" :key="index" class="text-center">
        <b-link :to="'/pressed-coin/' + p.catalog" class="pc-coin pc-coin-own">
          <AddCoinFavBtn :catalog="p.catalog"/>
          <img src="../assets/img/presscoins-own-coin.png" alt="Own Coin Icon" class="pc-coin-own-icon"/>
          <!-- <b-badge variant="primary" v-if="p.quantity" @click="editCoinQty(p.catalog)" v-b-tooltip.hover title="Change Quantity">Qty: {{p.quantity}}</b-badge> -->
          <b-img :src="'https://res.cloudinary.com/presscoins/image/upload/h_200,w_200,c_limit/d_contentNodes:presscoins-empty-coin-1.png/v1535414476/pressed-coins/' + p.catalog + '.jpg'" :alt="p.catalog" fluid></b-img>
          <!-- <b-modal :id="'coin-qty-' + p.catalog" centered title="Change quantity">
            <p>Removing coins from your collection will remove any associated notes and quantites.</p>
          </b-modal> -->
          <!-- <b-link class="text-danger" @click="removeCoin(p.catalog)" v-b-tooltip.hover title="Remove" style="position: absolute;right: 1rem;bottom: 0.75rem;"><font-awesome-icon :icon="['far', 'trash']" /></b-link>
          <b-modal :id="'coin-remove-' + p.catalog" centered title="Are you sure you want to remove this coin?">
            <p>Removing coins from your collection will remove any associated notes and quantites.</p>
          </b-modal> -->
          <!-- <b-link v-if="!p.note" class="pc-coin-note" @click="addCoinNote(p.catalog)" v-b-tooltip.hover title="Add Note" style="position: absolute;left: 1rem;bottom: 0.75rem;"><font-awesome-icon :icon="['far', 'comment-alt-plus']" /></b-link> -->
          <!-- <b-link v-if="p.note" class="pc-coin-note" @click="addCoinNote(p.catalog)" v-b-tooltip.hover title="View Note" style="position: absolute;left: 1rem;bottom: 0.75rem;"><font-awesome-icon :icon="['far', 'comment-alt-lines']" /></b-link> -->
          <!-- <b-modal :id="'coin-note-' + p.catalog" title="Coin Note" centered>
            <b-textarea maxlength="580" :value="p.note"></b-textarea>
          </b-modal> -->
        </b-link>
      </slide>
    </pc-card-carousel>
  </div>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig'
import CardCarousel from '@/components/CardCarousel'
import AddCoinFavBtn from '@/components/AddCoinFavBtn'

export default {
  name: 'UserCoinCollection',
  props: {
    limit: {
      type: Number,
      default: 24
    }
  },
  data () {
    return {
      presscoins: [] // Empty array to populate from firestore
    }
  },
  methods: {
    getPresscoins: function () {
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .orderBy('catalog')
        .limit(this.limit)
        .onSnapshot(querySnapshot => {
          let presscoinsArray = []

          querySnapshot.forEach(doc => {
            let coin = doc.data()
            coin.id = doc.id
            presscoinsArray.push(coin)
          })

          this.presscoins = presscoinsArray
        })
    },
    removeCoin: function (catalog) {
      this.$bvModal.show('coin-remove-' + catalog)
    },
    addCoinNote: function (catalog) {
      this.$bvModal.show('coin-note-' + catalog)
    },
    editCoinQty: function (catalog) {
      this.$bvModal.show('coin-qty-' + catalog)
    }
  },
  beforeMount () {
    this.getPresscoins()
  },
  components: {
    'pc-card-carousel': CardCarousel,
    AddCoinFavBtn
  }
}
</script>
