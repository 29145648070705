<template>
    <div class="faq">
        <b-jumbotron
          style="background:url(https://res.cloudinary.com/presscoins/image/upload/v1568166025/contentNodes/presscoins-walt-disney-world-pressed-coin-faq.jpg) no-repeat center / cover"
          header="Presscoins FAQ"
          lead="Answers to your common questions."
          class="text-white"
          fluid>
        </b-jumbotron>

        <b-container class="mb-5">
            <b-row>
                <b-col>
                    <div role="tablist">
                        <div class="mb-5" v-for="contentNode in contentNodes" :key="contentNode.id">
                            <h3 v-if="contentNode.title != null">{{ contentNode.title }}</h3>
                            <vue-markdown v-if="contentNode.body != null">{{ contentNode.body }}</vue-markdown>
                        </div>
                        <!-- <b-card no-body class="mb-1" v-for="contentNode in contentNodes" :key="contentNode.id">
                            <b-card-header header-tag="header" class="p-2" role="tab">
                                <b-link block href="#" v-b-toggle.#{{contentNode.id}} class="text-align-left" v-if="contentNode.title != null">{{ contentNode.title }}</b-link>
                            </b-card-header>
                            <b-collapse :id="contentNode.id" visible :accordion="contentNode.title" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text"><vue-markdown v-if="contentNode.body != null">{{ contentNode.body }}</vue-markdown></p>
                                </b-card-body>
                            </b-collapse>
                        </b-card> -->
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import gql from 'graphql-tag'
import VueMarkdown from 'vue-markdown'

export const contentNodes = gql`
  query contentNodes {
    contentNodes( orderBy: publishDate_DESC, 
    where: {
        status: PUBLISHED,
        contentNodeType: FAQ
    }) {
      id
      title
      body
    }
  }
`

export default {
  name: 'FAQ',
  metaInfo: {
    title: 'FAQ',
    meta: [
      { name: 'description', content: 'Answers to your common questions.' }
    ]
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  },
  components: {
    VueMarkdown
  }
}
</script>
