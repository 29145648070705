<template>
    <b-container class="my-5 text-center">
      <b-row>
        <b-col cols="12" md="6" lg="4" class="mx-auto">

          <div v-if="isAuthenticated">
            <h3>Logging Out</h3>
          </div>

          <div v-if="!isAuthenticated">
            <h3>You are now logged out.</h3>
            <img src="../assets/img/machine-waypoint.png" alt="404 Uh-oh" class="my-4" style="max-height: 10rem" />
            <b-button variant="primary" to="/login" block>Log in</b-button>
            <p><b-link to="/">or back Home</b-link></p>
          </div>

        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'logout',
  metaInfo: {
    title: 'Logout',
    meta: [
      { name: 'description', content: 'You are now logged out of your Presscoins account. Come back next time to start your Walt Disney World pressed coin collection.' }
    ]
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$store.dispatch('userLogout')
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated()
    }
  }
}
</script>
