<template>
  <div>
    <b-button @click="googleSocialLogin" block variant="secondary" class="b-google">
      <img src="../assets/img/google-logo.png" style="height:1.25rem" class="mr-2" alt="Google Logo">
      Log in with Google
    </b-button>
    <b-button @click="facebookSocialLogin" block variant="secondary" class="b-facebook">
      <font-awesome-icon class="mr-2" :icon="['fab', 'facebook-f']"/>
      Log in with Facebook
    </b-button>
  </div>
</template>
<script>
import firebase from '@firebase/app'
import { firebaseAuth } from '@/plugins/firebaseConfig'
import { userCollection } from '../plugins/firebaseConfig'

export default {
  name: 'SocialLogin',
  methods: {
    googleSocialLogin () {
      // TODO: move to store..
      const provider = new firebase.auth.GoogleAuthProvider()
      firebaseAuth.signInWithRedirect(provider)
        .then(result => {
          // TODO: some work to land on orginal page
          this.$router.replace('/home')
        })
        .catch(err => {
          alert('Oops. ' + err.message)
        })
    },
    facebookSocialLogin () {
      const provider = new firebase.auth.FacebookAuthProvider()
      firebaseAuth.signInWithRedirect(provider)
        .then(result => {
          // TODO: some work to land on orginal page
          this.$router.replace('/home')
        })
        .catch(err => {
          alert('Oops. ' + err.message)
        })
    }
  },
  async created () {
    // This process reads the result after a login with social provider

    try {
      const userCredential = await firebaseAuth.getRedirectResult()

      if (userCredential.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken
      }

      if (userCredential.user) {
        // redirect from google worked.

        // see if new user. for roles.

        console.log('getRedirectResult login via socal redirect', userCredential)
        // update any user fields that google may have newer info for.
        const userUpdate = {
          // Default user document, be sure matches SignUp
          email: userCredential.user.email,
          displayName: userCredential.user.displayName,
          userId: userCredential.user.uid
        }

        if (userCredential.additionalUserInfo.isNewUser) {
          // first time so add any defaults we want to be there.
          userUpdate.roles = []
        } else {
          // note: could pull firebase for current user document and do an update and then set it back.
        }

        userCollection.doc(userCredential.user.uid).set(userUpdate, { merge: true })
        this.$router.push({ name: 'Home' })
      }
    } catch (error) {
      // Handle Errors here.
      // var errorCode = error.code

      alert('Oops. ' + error.message)
      // The email of the user's account used.
      // var email = error.email
      // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential
      console.error('getRedirectResult error', error)
    }
  }
}
</script>
