import Vue from 'vue'
import Router from 'vue-router'

// Firebase
import { firebaseAuth } from '@/plugins/firebaseConfig'

// ////////////////////// Top Level //////////////////////
// Index - Home
import Home from '@/views/Home'
// Blog - News
import News from '@/views/News'
// Shop
import Shop from '@/views/Shop'
// Shop
import MachineOne from '@/views/MachineOne'
// Upgrade
// import Upgrade from '@/views/Upgrade'
// Checkout
// import Checkout from '@/views/Checkout'
// CheckoutSuccess
// import CheckoutSuccess from '@/views/CheckoutSuccess'
// Listings
import Listings from '@/views/Listings'
// Locations
import Locations from '@/views/Locations'
// Search
import Search from '@/views/Search'

// ////////////////////// Supporting Pages //////////////////////
// Page Template
import PageOne from '@/views/PageOne'
// Blog Page Template
import BlogOne from '@/views/BlogOne'
// Coin Page Template
import CoinOne from '@/views/CoinOne'
// Location Page Template
import LocationOne from '@/views/LocationOne'
// FAQ
import FAQ from '@/views/FAQ'
// 404
import PageNotFound from '@/views/PageNotFound'

// ////////////////////// Account //////////////////////
// Account Dashboard
import Account from '@/views/Account'
// Login
import Login from '@/views/Login'
// Logout
import Logout from '@/views/Logout'
// SignUp
import SignUp from '@/views/SignUp'
// User Collection
import Collection from '@/views/Collection'

// ////////////////////// Premium Feature //////////////////////
// Coin Album Landing Page
// import coinAlbumCollection from '@/views/CoinAlbumCollection'
// Coin Album Page Template
// import coinAlbumPage from '@/views/CoinAlbumPage'

// ////////////////////// Admin Tools //////////////////////
// Themed Checklist Generator
// import ChecklistGenerator from '@/views/ChecklistGenerator'

// ////////////////////// Coming Soon//////////////////////
// Machine Location Map
import Map from '@/views/Map'
// Add Machine Form
// import AddMachine from '@/views/AddMachine'
// User Submitted Machine Map
// import UserSubmittedMap from '@/views/UserSubmittedMap'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/home',
      redirect: '/'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        showLoginFooter: false
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      meta: {
        showLoginFooter: false
      }
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: SignUp,
      meta: {
        showLoginFooter: false
      }
    },
    // {
    //   path: '/add-machine',
    //   name: 'AddMachine',
    //   component: AddMachine
    // },
    // {
    //   path: '/user-map',
    //   name: 'UserSubmittedMap',
    //   component: UserSubmittedMap
    // },
    {
      path: '/pressed-coin-map',
      name: 'Pressed Coin Map',
      component: Map,
      meta: {
        showLoginFooter: false
      }
    },
    {
      path: '/map',
      redirect: '/pressed-coin-map'
    },
    {
      path: '/listings',
      name: 'Listings',
      component: Listings
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ,
      meta: {
        showLoginFooter: false
      }
    },
    {
      path: '/locations',
      name: 'Locations',
      component: Locations
    },
    {
      path: '/location/:slug',
      name: 'Location',
      component: LocationOne
    },
    {
      path: '/pressed-coin/:slug',
      name: 'Coin',
      component: CoinOne,
      meta: {
        showLoginFooter: false
      }
    },
    {
      path: '/machine/:slug',
      name: 'Machine',
      component: MachineOne,
      meta: {
        showLoginFooter: false
      }
    },
    {
      path: '/news',
      name: 'News',
      component: News
    },
    {
      path: '/shop',
      name: 'Shop',
      component: Shop
    },
    // {
    //   path: '/upgrade',
    //   name: 'Upgrade',
    //   component: Upgrade,
    //   meta: {
    //     showLoginFooter: false
    //   }
    // },
    // {
    //   path: '/mycollection-plus',
    //   redirect: '/upgrade'
    // },
    // {
    //   path: '/checkout',
    //   name: 'Checkout',
    //   component: Checkout,
    //   meta: {
    //     showLoginFooter: false
    //   }
    // },
    // {
    //   path: '/checkout-success',
    //   name: 'CheckoutSuccess',
    //   component: CheckoutSuccess,
    //   meta: {
    //     showLoginFooter: false
    //   }
    // },
    {
      path: '/page/:slug',
      name: 'Page',
      component: PageOne
    },
    {
      path: '/blog/:slug',
      name: 'Blog',
      component: BlogOne
    },
    // {
    //   path: '/checklist-generator/',
    //   name: 'ChecklistGenerator',
    //   component: ChecklistGenerator,
    //   meta: {
    //     requiresAuth: true,
    //     adminAuth: true,
    //     showSubscribe: false,
    //     showLoginFooter: false
    //   }
    // },
    // {
    //   path: '/coin-album',
    //   name: 'Coin Album Collection',
    //   component: coinAlbumCollection,
    //   meta: {
    //     requiresAuth: true,
    //     showSubscribe: false,
    //     adminAuth: true,
    //     showLoginFooter: false
    //   }
    // },
    // {
    //   path: '/coin-album/:slug',
    //   name: 'Coin Album',
    //   component: coinAlbumPage,
    //   meta: {
    //     requiresAuth: true,
    //     showSubscribe: false,
    //     showLoginFooter: false
    //   }
    // },
    {
      path: '/collection',
      name: 'Collection',
      component: Collection,
      meta: {
        requiresAuth: true,
        showLoginFooter: false
      }
    },
    {
      path: '/404',
      component: PageNotFound
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/blog',
      redirect: '/news'
    },
    {
      path: '/search',
      name: 'Search',
      component: Search,
      props: route => ({ query: route.query.q }),
      meta: {
        showLoginFooter: false
      }
    },
    {
      path: '/account',
      name: 'Account',
      component: Account,
      meta: {
        requiresAuth: true,
        showLoginFooter: false
      }
    }
  ]
})

// enforce logged in.
router.beforeEach((to, from, next) => {
  let currentUser = firebaseAuth.currentUser
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // let adminAuth = currentUser.data.role_id === 'ADMIN' // WIP: Adding admin role auth

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAuth && currentUser) {
    next()
  // } else if (requiresAuth && currentUser && !adminAuth) { // WIP: Adding admin role auth
  //   next('/login')
  // } else if (requiresAuth && currentUser && adminAuth) { // WIP: Adding admin role auth
  //   next()
  } else {
    next()
  }
})

export default router
