import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import router from '@/router'
import { firebaseAuth, userCollection } from '@/plugins/firebaseConfig'

Vue.use(Vuex)

// Example: https://github.com/ratracegrad/meal-prep/blob/master/src/store.js

let store = new Vuex.Store({
  state: {
    user: null,
    accountTempValue: '',
    presscoins: []
  },
  mutations: {
    setUser (state, payload) {
      console.log('setUser', payload)
      state.user = payload
    },
    setPresscoins (state, payload) {
      state.presscoins = payload
    },
    setUserDocument (state, payload) {
      // iser
      state.userDocument = payload
    }
  },
  actions: {
    userLogin ({ commit }, { email, password }) {
      console.log('userLogin')

      firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          // store user with email into firebase.

          console.log('Sucessful Login')
          commit('setUser', user.user)
          router.push('/page/about')
        })
        .catch((ex) => {
          console.error('Failed Login', ex)
          commit('setUser', null)
        })
    },
    userLogout ({ commit }) {
      firebaseAuth
        .signOut()
        .then(user => {
          console.log('Sucessful Logout')
          commit('setUser', null)
          // Important: Do a hard reset to avoid issue of getRedirectResult still existing.
          // Details: https://www.answiz.com/questions/28907/firebase-getredirectresult-is-being-called-after-logout
          window.location.href = '/logout'
        })
        .catch((ex) => {
          console.error('Failed Logout', ex)
          commit('setUser', null)
        })
    },
    addAccountValue ({ state }, model) {
      console.log('Document to save:', model)
      userCollection.doc(state.user.uid).collection('userCoinCollection').add({
        catalog: model.accountValue,
        quantity: '1'
      })
        .then(function () {
          console.log('Document successfully written!')
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })
    },
    getUserDoc ({ state, commit }) {
      userCollection.doc(state.user.uid).get()
        .then(function (doc) {
          if (doc.exists) {
            console.log('Document data:', doc.data())
            commit('setUserDocument', doc.data())
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        }).catch(function (error) {
          console.log('Error getting document:', error)
        })
    },
    fetchUserProfile ({ commit, state }) {
      userCollection.doc(state.currentUser.uid).get().then(res => {
        commit('setUserProfile', res.data())
      }).catch(err => {
        console.log(err)
      })
    },
    getAdmin ({ state, commit }) {
      userCollection.doc(state.currentUser.uid).where('admin', '==', true).get().then(res => {
        commit('setAdminValue', res.data())
      }).catch(err => {
        console.log(err)
      })
    }
    // fetchPressCoinsAll ({ commit, state }) {
    //   const markers = []
    //   console.log('loading press coins into vue state')
    //   presscoinsCollection.get()
    //     .then(querySnapshot => {
    //       querySnapshot.docs.forEach(doc => {
    //         markers.push(doc.data())
    //       })

    //       // so we could load these all on demand but the web call is really small. so just loading everything for now.
    //       // note this is causing a "FirebaseError: Quota exceeded." because we are on free plan, ...  we're being throttled by calls per second .
    //       // console.log(querySnapshot.size) // Displays total size of collection
    //       store.commit('setPresscoins', markers)
    //     }).catch(err => {
    //       console.log(err)
    //     })
    // }
  },
  getters: {
    isAuthenticated (state) {
      return () => { // return a function so its will always be up to date
        return state.user !== null && state.user !== undefined
      }
    },
    isAdmin (state) {
      return () => { // return a function so its will always be up to date
        if (state.userDocument !== undefined &&
          state.userDocument.roles !== undefined) {
          console.log('roles', state.userDocument.roles, state.userDocument.roles.includes('admin'))
          return state.userDocument.roles.includes('admin')
        }
        return false
      }
    },

    isRoleOnUser (state) {
      return (roleName) => { // return a function so its will always be up to date
        if (state.userDocument !== undefined &&
          state.userDocument.roles !== undefined) {
          console.log('roles', state.userDocument.roles, state.userDocument.roles.includes('admin'))
          return state.userDocument.roles.includes(roleName)
        }
        return false
      }
    }

  }
})

// on page load or when a user logs in or out, save that in the store
firebaseAuth.onAuthStateChanged((user) => {
  const isAuthenticated = user !== null
  const email = isAuthenticated ? user.email : ''
  console.log('user is logged in', isAuthenticated, email)
  store.commit('setUser', user)

  // WIP ADMIN USER
  if (isAuthenticated) {
    // we are logged in so get the firebase doc for the user and store it.
    store.dispatch('getUserDoc')
  }
})

// store.dispatch('fetchPressCoinsAll')

export default store
