<template>
          <ais-instant-search
            index-name="prod_PRESSCOINS"
            :search-client="searchClient"
            :routing="routing"
            :class-names="{
              'ais-InfiniteHits-loadMore': 'btn btn-primary',
            }"
          >
            <b-container fluid>
              <b-row>
                <b-col cols="12">
                  <b-button v-b-toggle.collapse-1 variant="outline-primary" size="sm" class="d-md-none float-right my-2"><font-awesome-icon :icon="['far', 'filter']" /> Filter</b-button>
                </b-col>
              <b-col id="collapse-2" cols="12" md="2" class="presscoin-sidebar">
                <b-collapse id="collapse-1" class="d-md-block">
                <h4>Coin Type</h4>
                <ais-refinement-list
                attribute="coin"
                class="mb-4"
                :class-names="{
                  'ais-RefinementList-count': 'badge badge-secondary'
                }"
                >
                </ais-refinement-list>

                <h4>Available</h4>
                <ais-refinement-list
                  attribute="available"
                  class="mb-4"
                  :class-names="{
                    'ais-RefinementList-count': 'badge badge-secondary'
                  }"
                />

                <h4>Location</h4>
                <ais-refinement-list
                attribute="place"
                :limit="5"
                show-more
                class="mb-4"
                :class-names="{
                  'ais-RefinementList-showMore': 'btn btn-link btn-sm',
                  'ais-RefinementList-count': 'badge badge-secondary'
                }"
                >
                </ais-refinement-list>

                <h4>Theme</h4>
                <ais-refinement-list
                attribute="theme"
                :limit="5"
                show-more
                :class-names="{
                  'ais-RefinementList-showMore': 'btn btn-link btn-sm',
                  'ais-RefinementList-count': 'badge badge-secondary'
                }"
                >
                </ais-refinement-list>

                <!-- <h4>Year</h4>
                <ais-refinement-list
                attribute="firstseen"
                :limit="5"
                show-more
                class="mb-4"
                :class-names="{
                  'ais-RefinementList-showMore': 'btn btn-link btn-sm',
                  'ais-RefinementList-count': 'badge badge-secondary'
                }"
                >
                </ais-refinement-list> -->
                </b-collapse>
            </b-col>
            <b-col cols="12" md="10" class="pt-md-4 presscoin-content">
              <b-container class="mb-2 pc-search__sticky-bar">
                <b-row>
                  <b-col cols="12" class="p-0">
                      <label class="d-block d-md-inline-block mb-0 mr-md-2">Search 2,000+ pressed coins</label>
                      <small class="text-muted d-block d-md-inline-block mb-2">(Powered by <b-link href="https://www.algolia.com" target="_blank"><font-awesome-icon :icon="['fab', 'algolia']" /> algolia</b-link>)</small>
                      <b-input-group class="pc-search__input-group">
                        <ais-search-box show-loading-indicator class="d-block">
                          <input
                            slot-scope="{ currentRefinement, isSearchStalled, refine }"
                            :value="currentRefinement"
                            @input="refine($event.currentTarget.value)"
                            placeholder="Search some of our favs like Mickey, Frozen, WDW19068"
                            autocomplete="off"
                            class="form-control form-control-lg d-block"
                            style="border-radius: 6rem 0 0 6rem"
                          />
                          <!-- <span :hidden="!isSearchStalled">Loading...</span> -->
                        </ais-search-box>
                        <b-input-group-append>
                          <b-button variant="primary" class="px-3"><font-awesome-icon :icon="['far', 'search']" class="mr-1"></font-awesome-icon><span class="d-none d-md-inline-block">Search</span></b-button>
                        </b-input-group-append>
                      </b-input-group>

                    <!-- <ais-search-box show-loading-indicator>
                      <input
                        slot-scope="{ currentRefinement, isSearchStalled, refine }"
                        :value="currentRefinement"
                        @input="refine($event.currentTarget.value)"
                        placeholder="Search pressed coins by name or catalog number..."
                        class="form-control"
                      />
                      <span :hidden="!isSearchStalled">Loading...</span>
                      <div slot="submit-icon">🔎</div>
                    </ais-search-box> -->
                  </b-col>
                </b-row>
              </b-container>

              <b-container>
                <b-row>
                  <b-col class="p-0">
                    <ais-stats>
                        <p slot-scope="{ nbHits }" class="mb-2">Showing {{ nbHits | numFormat }} pressed coins</p>
                    </ais-stats>
                  </b-col>
                  <!-- <b-col class="p-0 text-right">
                    <b-btn @click="gallery = true" variant="link" size="lg" v-b-tooltip.hover title="List View" class="px-2"><font-awesome-icon :icon="['far', 'th-list']" /></b-btn>
                    <b-btn @click="gallery = false" variant="link" size="lg" v-b-tooltip.hover title="Gallery View" class="px-2"><font-awesome-icon :icon="['far', 'th']" /></b-btn>
                  </b-col> -->
                </b-row>
              </b-container>

              <b-container class="p-0 mb-4 text-center">
                <b-row>
                  <b-col class="p-0 ">
                    <b-list-group class="pc-search_list">
                      <ais-infinite-hits
                        class="mb-2"
                        :class-names="{
                          'ais-InfiniteHits-loadMore': 'btn btn-primary mt-2 mx-auto',
                          'ais-InfiniteHits-item': 'list-group-item list-group-item-action text-left',
                          'ais-InfiniteHits-loadMore--disabled': 'd-none'
                        }">
                        <router-link :to="'/pressed-coin/' + item.catalog" slot="item" slot-scope="{ item }" class="pc-search__item">
                          <b-container class="position-relative">
                            <b-row align-v="center">
                              <b-col cols="5" md="4" class="text-center p-0">
                                <b-img :src="'https://res.cloudinary.com/presscoins/image/upload/h_200,w_200,c_limit/d_contentNodes:presscoins-empty-coin-1.png/v1535414476/pressed-coins/' + item.catalog + '.jpg'" :alt="item.shortdesc" fluid class="pc-search__img"></b-img>
                              </b-col>
                              <b-col cols="7" md="8">
                                <h5 class="mb-1 pc-search_title"><add-to-collection-btn :catalog="item.catalog" :title="item.shortesc" :coinType="item.coin" :buttonSmall="true" class="d-inline-block mr-1"></add-to-collection-btn> <AddCoinFavBtn :catalog="item.catalog" inline class="mr-1"></AddCoinFavBtn> {{ item.shortdesc }}</h5>
                                <!-- <b-btn variant="outline-primary" size="sm" to="/collection"><font-awesome-icon :icon="['far', 'stars']" /> Add to want list</b-btn> -->
                                <p class="mb-1 d-none d-md-block">{{ item.description }}</p>
                                <!-- <b-badge class="mb-1" v-if="item.variation">Variation: {{ item.variation }}</b-badge> -->
                                <!-- <b-badge class="mb-1" v-if="item.series">Series: {{ item.series }}</b-badge> -->
                                <small class="text-muted">{{ item.catalog }} </small> <b-badge variant="primary" v-if="item.available == 'True'">Available</b-badge> <b-badge variant="secondary" v-if="item.available == 'False'">Retired</b-badge>
                              </b-col>
                            </b-row>
                          </b-container>
                        </router-link>
                      </ais-infinite-hits>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-container>

              <!-- <ais-hits v-if="gallery" inline-template class="mb-2">
                <b-card-group columns>
                  <b-card slot="item" slot-scope="{ item }"
                    class="text-center"
                  ><b-link :to="'/pressed-coin/' + result.Catalog">
                  <img :src="'https://res.cloudinary.com/presscoins/image/upload/v1535338187/pressed-coins/' + result.Catalog + '.jpg'" :alt="result.ShortDescription" style="max-width:15rem;max-height:15rem;" />
                  </b-link>
                    <div slot="footer">
                      <small class="text-muted">{{ item.Catalog }} | {{ result.Coin }}</small>
                    </div>
                  </b-card>
                </b-card-group>
              </ais-hits> -->

              <ais-state-results>
                <template slot-scope="{ query, hits }">
                  <div v-if="hits.length">

                  </div>
                  <div class="text-center" v-if="!hits.length">
                    <img src="../assets/img/machine-waypoint.png" alt="404 Uh-oh" style="width:100%;max-width:10rem" />
                    <h2>No pressed coins found for <i>{{ query }}</i>.</h2>
                    <!-- <p>If you believe that found a pressed coin that isn't in our databse you can now request to add it!</p> -->
                    <!-- <b-btn variant="primary" to="/add-machine">Add Pressed Coin</b-btn> -->
                  </div>
                </template>
              </ais-state-results>

              <!-- <ais-pagination>
                <span slot="first">first</span>
                <span slot="previous">prev</span>
                <span slot="item" slot-scope="{value, active}" :style="{color: active ? 'red' : 'green'}">
                  {{value.toLocaleString()}}
                </span>
                <span slot="next">next</span>
                <span slot="last">last</span>
              </ais-pagination> -->

                </b-col>
              </b-row>
            </b-container>
          </ais-instant-search>
</template>

<script>
import AddToCollectionBtn from '@/components/AddToCollectionBtn'
import AddCoinFavBtn from '@/components/AddCoinFavBtn'
import { history } from 'instantsearch.js/es/lib/routers'
import { simple } from 'instantsearch.js/es/lib/stateMappings'
import algoliasearch from 'algoliasearch/lite'
const searchClient = algoliasearch('TH30VT8HCR', 'ce3037c0e5ae85ac612b7181080f13ef')

export default {
  name: 'Search',
  metaInfo: {
    title: 'Search',
    meta: [
      { name: 'description', content: 'Find the oldest and newest Walt Disney World pressed coins ever made.' }
    ]
  },
  data () {
    return {
      searchClient,
      gallery: false,
      routing: {
        router: history(),
        stateMapping: simple()
      }
    }
  },
  components: {
    AddCoinFavBtn,
    AddToCollectionBtn
  }
}
</script>

<style lang="scss">
.pc-search__item {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
.ais-InfiniteHits-list {
  box-shadow: 3px 3px 0.5rem rgba(#286efa,0.3);
}
.pc-search_list {
  .list-group-item {
    border-left: none;
    border-right: none;
  }

  .list-group-item:first-child {
    border-top: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.pc-search_title {
  color: #349cf7;
}

.pc-search__img {
  max-height: 10rem;
  max-width: 10rem;
}

@media(min-width:768px) {
  .pc-search__img {
    max-height: none;
  }
}

.presscoin-sidebar h4 {
  color: #17213a;
}

.ais-SearchBox {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
// .list-group-item {
//   min-height: 15rem;
// }
// .pc-search__sticky-bar {
//   position: sticky;
//   top: 0.5rem;
//   z-index: 999;
// }

// @media(min-width:768px) {
//   .pc-search__sticky-bar {
//     position: static;
//     top: 2.5rem;
//   }
// }
</style>
