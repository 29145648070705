<template>
    <div class="coins-by-location">
        <pc-card-carousel>
            <slide v-for="(p, index) in presscoins" :key="index" class="text-center">
                <b-link :to="'/pressed-coin/' + p.catalog" class="pc-coin">
                  <div>
                    <AddCoinFavBtn :catalog="p.catalog"/>
                    <b-img :src="'https://res.cloudinary.com/presscoins/image/upload/h_200,w_200,c_limit/d_contentNodes:presscoins-empty-coin-1.png/v1535414476/pressed-coins/' + p.catalog + '.jpg'" :alt="p.catalog" fluid></b-img>
                    <span class="pc-coin-title">{{ p.shortdesc }}</span> <b-badge variant="primary" v-if="p.available == 'True'">Available</b-badge> <b-badge variant="secondary" v-if="p.available == 'False'">Retired</b-badge>
                    <add-to-collection-btn :catalog="p.catalog" :title="p.shortdesc" :coinType="p.coin" :buttonSmall="true" class="pc-coin-add" />
                  </div>
                </b-link>
            </slide>
        </pc-card-carousel>
    </div>
</template>

<script>
import { presscoinsCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig
import CardCarousel from '@/components/CardCarousel'
import AddToCollectionBtn from '@/components/AddToCollectionBtn'
import AddCoinFavBtn from '@/components/AddCoinFavBtn'

export default {
  name: 'CoinsBySeries',
  props: {
    variation: {
      type: String,
      default: '2002'
    }
  },
  data () {
    return {
      presscoins: [] // Empty array to populate from firestore
    }
  },
  methods: {
    getPresscoins: function () {
      presscoinsCollection
        .where('variation', '==', this.variation)
        .limit(24)
        .onSnapshot(querySnapshot => {
          let presscoinsArray = []

          querySnapshot.forEach(doc => {
            let coin = doc.data()
            coin.id = doc.id
            presscoinsArray.push(coin)
          })

          this.presscoins = presscoinsArray
        })
    }
  },
  beforeMount () {
    this.getPresscoins()
  },
  components: {
    'pc-card-carousel': CardCarousel,
    AddToCollectionBtn,
    AddCoinFavBtn
  }
}
</script>

<style lang="scss">
.pc-coin {
  width: 100%;
  width: 16rem;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border-radius: 0.8rem;
  margin: 0.3rem 0;
  background: #fff;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 1rem;
  line-height: 1.2;
  overflow: hidden;
  box-shadow: 3px 3px 0.5rem rgba(#286efa,0.3);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0 0 3px rgba(#286efa,0.9), 3px 3px 1rem rgba(#286efa,0.5);
    text-decoration: none;
  }

  img {
    display: flex;
    margin: 0 auto;
    max-width: 13rem;
    width: auto;
    max-height: 13rem;
  }

  .pc-coin-title {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    padding: 0.5rem;
    border-radius: 0 0.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
  }

  &.pc-coin-own:hover {
    box-shadow: 0 0 0 3px rgba(#58c70d,0.9), 3px 3px 1rem rgba(#286efa,0.5);
  }

  .pc-coin-own-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.5rem;
    height: 1.5rem;
  }

  &.pc-coin--large {
    width: 100%;
    max-width: 32rem;
    height: auto;
    max-height: 32rem;
    margin-bottom: 1rem;
    padding: 2rem;

    img {
      max-width: 24rem;
      width: auto;
      max-height: 24rem;
    }
  }
}
</style>
