<template>
  <b-navbar toggleable="md" type="dark" sticky>

    <b-navbar-brand to="/">
      <img src="../assets/img/presscoins-logo.svg" class="d-inline-block align-top presscoins-logo" alt="Presscoins Logo">
    </b-navbar-brand>

    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

    <b-collapse is-nav id="nav_collapse">

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <b-nav-item to="/news">News</b-nav-item>
        <!-- <b-nav-item to="/shop">Shop</b-nav-item> -->
        <b-nav-item to="/map">Map</b-nav-item>
        <b-nav-item to="/listings">Listings</b-nav-item>
        <b-nav-item to="/locations">Locations</b-nav-item>
        <b-nav-item to="/page/about">About</b-nav-item>

        <b-nav-form @submit.prevent="submit" class="my-2 my-md-0 mx-md-2 presscoins-search">
          <b-input-group size="sm">
            <b-form-input type="search" placeholder="Search Pressed Coins" class="presscoins-search__input" v-model="search" autocomplete="off" />
            <b-input-group-append>
              <b-btn type="submit" variant="primary" class="presscoins-search__btn"><font-awesome-icon :icon="['far', 'search']" /></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
        <b-nav-item v-if="!isAuthenticated()" id="qsLoginBtn" to="/login"> Log in
        </b-nav-item>
        <b-nav-item-dropdown right no-caret v-if="isAuthenticated()">
          <!-- Using button-content slot -->
          <template slot="button-content"><img v-if="userPhoto" :src="userPhoto" class="user-profile-img" :alt="displayName"/><img v-if="!userPhoto" src="../assets/img/default-user-img.jpg" class="user-profile-img" alt="User Image"/></template>
          <p class="px-4 mb-0">Hello, <span v-if="displayName">{{displayName}}</span><span v-if="!displayName">Presscoins fan!</span></p>
          <b-dropdown-divider />
          <b-dropdown-item to="/account">Account</b-dropdown-item>
          <b-dropdown-item to="/collection">Collection</b-dropdown-item>

          <b-dropdown-item v-if="isRoleOnUser('tester')" to="/coin-album">Adventure Albums <b-badge variant="primary">New</b-badge></b-dropdown-item>
          <b-dropdown-item to="/checklist-generator" v-if="isRoleOnUser('admin')">Checklist Generator <b-badge variant="warning">Admin Only</b-badge></b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item id="qsLogoutBtn" to="/logout">Log out</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item v-if="isAuthenticated()" to="/collection"><font-awesome-icon :icon="['far', 'stars']" class="mr-2" /><UserCoinCollectionCount/></b-nav-item>
        <b-nav-item v-if="!isAuthenticated()" id="qsLoginBtn"  to="/sign-up"> Sign up</b-nav-item>
      </b-navbar-nav>

    </b-collapse>
  </b-navbar>
</template>
<script>
import UserCoinCollectionCount from '@/components/UserCoinCollectionCount'
import { mapGetters } from 'vuex'
export default {
  name: 'HeaderNav',
  data () {
    return {
      search: ''
    }
  },
  methods: {
    submit () {
      this.$router.push('/search/?query=' + this.search)
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isAuthenticated',
      'isRoleOnUser'
    ]),
    displayName () {
      return this.$store.state.user ? this.$store.state.user.displayName : ''
    },
    userPhoto () {
      return this.$store.state.user ? this.$store.state.user.photoURL : ''
    },
    userEmail () {
      return this.$store.state.user ? this.$store.state.user.email : ''
    }
  },
  components: {
    UserCoinCollectionCount
  }
}
</script>
