<template>
  <div class="listings">
    <b-jumbotron
      style="background:url(https://res.cloudinary.com/presscoins/image/upload/v1568166056/contentNodes/presscoins-walt-disney-world-pressed-coin-listings.jpg) no-repeat center / cover"
      header="Pressed Coin Listings"
      lead="Plan your next Walt Disney World trip with our free, printable checklists."
      class="text-white"
      fluid>
    </b-jumbotron>

    <b-container>
        <b-row>
          <b-col>
            <h1>Current Pressed Coin Listings</h1>
            <currentlistings></currentlistings>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>Previous Pressed Coin Listings</h2>
            <b-list-group class="mb-4">
              <b-list-group-item
                v-for="contentNode in contentNodes"
                :key="contentNode.id"
                class="d-flex justify-content-between align-items-center w-100 p-2"
              >
                <p class="m-0">{{ contentNode.title }}</p>
                <small class="text-muted">{{ contentNode.publishDate | moment("from", "now") }} | {{ contentNode.attachment.size | prettyBytes}}</small>
                <b-button :href="contentNode.attachment.url" target="_blank" variant="outline-primary" size="sm"><font-awesome-icon :icon="['far', 'file-download']" /> Download</b-button>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import CurrentListings from '@/components/CurrentListings'

// export const listingNodes = gql`
//   query listingNodes {
//     contentNodes(where: { contentNodeType: Listing }) {
//       id
//       contentNodeType
//       title
//       slug
//       publishDate
//       attachment {
//         url
//         size
//       }
//     }
//   }
// `

export const contentNodes = gql`
  query contentNodes {
    contentNodes( orderBy: publishDate_DESC, 
    where: {
        status: PUBLISHED,
        contentNodeType: Listing,
        body: ""
    }) {
      id
      contentNodeType
      title
      slug
      publishDate
      attachment {
        url
        size
      }
    }
  }
`

export default {
  name: 'Listings',
  metaInfo: {
    title: 'Listings & Checklists for Pressed Coins',
    meta: [
      { name: 'description', content: 'Plan your next Walt Disney World trip with our free, printable checklists.' }
    ]
  },
  components: {
    'currentlistings': CurrentListings
  },
  data: () => ({
    loading: 0,
    contentNodes: null
    // listingNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
    // listingNodes: {
    //   query: listingNodes
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {

}
</style>
