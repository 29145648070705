<template>
  <div id="app">
    <div class="d-block px-2 text-center text-bold text-white presscoins-welcome"><span class="d-none d-md-inline-block">👋</span>
      <small><span class="d-none d-md-inline-block">Welcome to </span> Presscoins Beta |
        <b-link
          href="https://www.presscoins.com"
          class="text-white link__arrow"
        >Return to Classic Mode</b-link>
      </small>
    </div>

    <app-header/>

    <router-view :key="$route.fullPath"></router-view>

    <b-container v-if="$route.meta.showLoginFooter !== false">
      <b-row>
        <b-col>
          <b-card class="mb-5 text-center">
            <div v-if="isAuthenticated">
              <h4>Your Collection Starts Here</h4>
              <p>Start adding new coins to your collection today.</p>
              <b-btn variant="primary" to="/collection">See your collection</b-btn>
              <router-link id="qsLogoutBtn" class="btn btn-margin" to="/logout">Log out</router-link>
            </div>

            <div v-if="!isAuthenticated">
              <h4>Create a Presscoins Account</h4>
              <p>By creating a Presscoins account you can start tracking your pressed coin collection and more!</p>
              <router-link id="qsLoginBtn" class="btn btn-primary btn-margin" to="/sign-up">Sign up</router-link>
              <router-link id="qsLoginBtn" class="btn btn-margin" to="/login">Log in</router-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <app-mailchimp-subscribe v-if="$route.meta.showSubscribe !== false"/>

    <app-footer/>

    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import MailchimpSubscribe from '@/components/MailchimpSubscribe'
import FooterNav from '@/components/FooterNav'

// import AuthService from './auth/AuthService'
// const auth = new AuthService()

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Default Title',
    // all titles will be injected into this template
    titleTemplate: '%s | Presscoins'
  },
  data () {},
  methods: {
    login: function () {}, // auth.login,
    logout: function () {} // auth.logout,
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated()
    }
  },
  components: {
    'app-header': HeaderNav,
    'app-footer': FooterNav,
    'app-mailchimp-subscribe': MailchimpSubscribe
  }
}
</script>
