<template>
  <div>
    <article v-for="contentNode in contentNodes" v-bind:key="contentNode.id">

    <b-img v-if="contentNode.headerImage != null" :src="contentNode.headerImage.url" :alt="contentNode.title" fluid-grow class="mb-5" />

    <b-container class="mb-5" :class="{ 'mt-3 mt-md-5' : contentNode.headerImage == null }">
        <b-row>
            <b-col lg="2">
            </b-col>
            <b-col lg="8">
              <h1>{{ contentNode.title }}</h1>

              <p class="lead" v-if="contentNode.subTitle != null">{{ contentNode.subTitle }}</p>
              <!--
              :description="contentNode.socialDescription"
              :quote="contentNode.excerpt"
              v-if="contentNode.socialDescription != null"
              :media="contentNode.socialImage.url" -->

              <social-sharing url="https://beta.presscoins.com/"
                      :title="contentNode.title"
                      hashtags="presscoins"
                      twitter-user="presscoins"
                      class="mb-4"
                      inline-template>
                <div>
                  <network network="facebook">
                    <b-badge pill class="px-3 py-2 b-facebook mr-1" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/> Facebook</b-badge>
                  </network>
                  <network network="pinterest">
                    <b-badge pill class="px-3 py-2 b-pinterest mr-1" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'pinterest' }"/> Pinterest</b-badge>
                  </network>
                  <network network="twitter">
                    <b-badge pill class="px-3 py-2 b-twitter" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/> Twitter</b-badge>
                  </network>
                </div>
              </social-sharing>

              <p class="lead" v-if="contentNode.intro != null">{{ contentNode.intro }}</p>

              <vue-markdown v-if="contentNode.body != null">{{ contentNode.body }}</vue-markdown>

              <hr />
              <p class="text-muted" v-if="contentNode.outro != null">{{ contentNode.outro }}</p>

              <social-sharing url="https://beta.presscoins.com/"
                      :title="contentNode.title"
                      hashtags="presscoins"
                      twitter-user="presscoins"
                      class="mb-2"
                      inline-template>
                <div>
                  <network network="facebook">
                    <b-badge pill variant="primary" class="px-3 py-2 b-facebook mr-1"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/> Facebook</b-badge>
                  </network>
                  <network network="pinterest">
                    <b-badge pill variant="primary" class="px-3 py-2 b-pinterest mr-1"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'pinterest' }"/> Pinterest</b-badge>
                  </network>
                  <network network="twitter">
                    <b-badge pill variant="primary" class="px-3 py-2 b-twitter"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/> Twitter</b-badge>
                  </network>
                </div>
              </social-sharing>
              <div>
                <div v-if="contentNode.contributors != null">
                  <span v-for="contributor in contentNode.contributors" v-bind:key="contributor.id" >
                    <b-img thumbnail fluid src="contributor.profileImage.url" :alt="contributor.firstName + contributor.lastName" v-if="contentNode.profileImage != null" rounded="circle" width="75" height="75" />
                    By
                    <b-link :to="'/contributor/' + contributor.slug">{{ contributor.firstName }} {{ contributor.lastName }}</b-link>
                    <span v-if="contentNode.twitterHandle != null">
                       | <b-link :href="'https://www.twitter.com/' + contributor.twitterHandle" target="_blank">@{{ contributor.twitterHandle }}</b-link> |
                    </span>
                  </span>
                </div>
                <span >{{ contentNode.publishDate | moment("dddd, MMMM Do YYYY") }}</span>
              </div>
              <!-- <div v-if="contentNode.keywords != null">
                <b-link :to="'/keyword/' + keyword.slug" v-for="keyword in contentNode.keywords" :key="keyword.id" >
                  <b-btn class="mr-1" variant="outline-primary" size="sm">{{ keyword.keyword }}</b-btn>
                </b-link>
              </div> -->
          </b-col>
          <b-col lg="2">
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <h3 class="mt-5">More Great Pressed Coin Articles</h3>
          <blogs></blogs>
          <b-link to="/news" variant="link">See All Articles <font-awesome-icon :icon="['far', 'angle-right']" /></b-link>
        </b-row>
    </b-container>
    </article>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import VueMarkdown from 'vue-markdown'
import BlogsSnippet from '@/components/BlogsSnippet'

export const contentNodes = gql`
  query contentNodes($id: String!) {
    contentNodes( orderBy: publishDate_DESC,
    where: { 
      slug: $id,
      status: PUBLISHED,
      contentNodeType: BlogPost
    }) {
      id
      title
      slug
      publishDate
      socialDescription
      excerpt
      body
      socialImage {
        url
      }
      contributors {
        firstName
        lastName
        slug
      }
      keywords {
        keyword
        slug
      }
    }
  }
`

export default {
  name: 'Blog',
  metaInfo () {
    return {
      title: 'Article',
      // title: this.contentNodes[0].title,
      meta: [
        // Schema.org for Google
        // { name: 'name', content: this.contentNodes[0].title },
        // { name: 'description', content: this.contentNodes[0].socialDescription },
        // { name: 'image', content: this.contentNodes[0].socialImage.url },
        // Twitter
        { name: 'twitter:card', content: 'summary' },
        // { name: 'twitter:title', content: this.contentNodes[0].title },
        // { name: 'twitter:description', content: this.contentNodes[0].socialDescription },
        { name: 'twitter:site', content: '@presscoins' },
        // { name: 'twitter:image:src', content: this.contentNodes[0].socialImage.url },
        // Open Graph general (Facebook, Pinterest & Google+)
        // { name: 'og:title', content: this.contentNodes[0].title },
        // { name: 'og:description', content: this.contentNodes[0].socialDescription },
        // { name: 'og:image', content: this.contentNodes[0].socialImage.url },
        { name: 'og:url', content: 'https://beta.presscoins.com' },
        { name: 'og:site_name', content: 'Presscoins' },
        { name: 'og:locale', content: 'en_US' },
        { name: 'fb:admins', content: '235725896469437' },
        { name: 'fb:app_id', content: '2072942123022764' },
        { name: 'og:type', content: 'article' },
        // Open Graph - Article
        // { name: 'article:section', content: this.contentNodes[0].title },
        // { name: 'article:published_time', content: this.contentNodes[0].publishDate },
        { name: 'article:author', content: 'Presscoins' }
        // { name: 'article:tag', content: this.contentNodes[0].keywords.keyword }
      ]
    }
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes,
      variables () {
        return {
          id: this.$route.params.slug
        }
      }
    }
  },
  components: {
    VueMarkdown,
    'blogs': BlogsSnippet
  }
}
</script>

<style lang="scss">
  article img {
    max-width: 100%;
    height: auto;
    border-radius: 3px;
  }
</style>
