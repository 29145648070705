<template>
  <div class="add-to-collection-btn_container">
    <!-- Button Regular -->
    <div v-if="buttonSmall && !set">
      <b-link to="/login" v-if="!isAuthenticated" variant="primary" v-b-tooltip.hover title="Add Coin"><img src="../assets/img/presscoins-add-coin-dark.png" alt="Add Coin" style="max-width: 1.5rem;vertical-align:text-bottom" /> </b-link>
      <b-link v-if="this.quantity == 0 && isAuthenticated" @click="addToCollection();" variant="primary" v-b-tooltip.hover title="Add Coin"><img src="../assets/img/presscoins-add-coin-dark.png" alt="Add Coin" style="max-width: 1.5rem;vertical-align:text-bottom" /></b-link>
      <b-link to="/collection" v-if="this.quantity >= 1" variant="success" v-b-tooltip.hover title="I Got It"><img src="../assets/img/presscoins-own-coin.png" alt="I Got It" style="max-width: 1.5rem;vertical-align:text-bottom" /></b-link>
    </div>

    <!-- Button Small -->
    <div v-if="!buttonSmall && !set">
      <b-button to="/login" v-if="!isAuthenticated" variant="primary" block><img src="../assets/img/presscoins-add-coin.png" alt="Add Coin" style="max-width: 1.5rem;vertical-align:text-bottom" />  Add to Collection</b-button>
      <b-button v-if="this.quantity == 0 && isAuthenticated" @click="addToCollection();" variant="primary" block><img src="../assets/img/presscoins-add-coin.png" alt="Add Coin" style="max-width: 1.5rem;vertical-align:text-bottom" /> Add to Collection</b-button>
      <b-button variant="success" v-if="this.quantity >= 1" to="/collection" block><img src="../assets/img/presscoins-own-coin-white.png" alt="I Got It" style="max-width: 1.5rem;vertical-align:text-bottom" /> I Got It</b-button>
    </div>

    <!-- Button Set Regular -->
    <div v-if="buttonSmall && set">
      <b-link to="/login" v-if="!isAuthenticated" variant="primary" v-b-tooltip.hover title="Add Coin"><img src="../assets/img/presscoins-add-coin-dark.png" alt="Add Entire Set" style="max-width: 1.5rem;vertical-align:text-bottom" /> </b-link>
      <b-link v-if="this.quantity == 0 && isAuthenticated" @click="addToCollection();" variant="primary" v-b-tooltip.hover title="Add Entire Set"><img src="../assets/img/presscoins-add-coin-dark.png" alt="Add Entire Set" style="max-width: 1.5rem;vertical-align:text-bottom" /></b-link>
      <b-link to="/collection" v-if="this.quantity >= 1" variant="success" v-b-tooltip.hover title="I Got It"><img src="../assets/img/presscoins-own-coin.png" alt="I Got Them All" style="max-width: 1.5rem;vertical-align:text-bottom" /></b-link>
    </div>

    <!-- Button Set Small -->
    <div v-if="!buttonSmall && set">
      <b-button to="/login" v-if="!isAuthenticated" variant="primary" block><img src="../assets/img/presscoins-add-coin.png" alt="Add Coin" style="max-width: 1.5rem;vertical-align:text-bottom" />  Add Set to Collection</b-button>
      <b-button v-if="this.quantity == 0 && isAuthenticated" @click="addToCollection();" variant="primary" block><img src="../assets/img/presscoins-add-coin.png" alt="Add Coin" style="max-width: 1.5rem;vertical-align:text-bottom" /> Add Set to Collection</b-button>
      <b-button variant="success" v-if="this.quantity >= 1" to="/collection" block><img src="../assets/img/presscoins-own-coin-white.png" alt="I Got It" style="max-width: 1.5rem;vertical-align:text-bottom" /> I Got Them All</b-button>
    </div>

    <!-- Button Toast -->
    <b-toast :id="this.catalog" toaster="b-toaster-bottom-right" solid>
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto"><img src="../assets/img/presscoins-add-coin-dark.png" alt="Add Coin" style="max-width: 1.5rem;vertical-align:text-bottom" /> {{ this.catalog }}</strong>
        </div>
        <div class="text-center">
          <h6>{{ this.title }} pressed <span v-if="this.coinType == 'Cent'">penny</span> <span v-if="this.coinType != 'Cent'">{{this.coinType}}</span> has been added to your collection!</h6>
          <b-link to="/collection">
            <img :src="'https://res.cloudinary.com/presscoins/image/upload/v1535338187/pressed-coins/' + this.catalog + '.jpg'" style="max-height: 8rem" class="mb-2 float-left" />
          </b-link>
          <br>
          <b-btn variant="primary" to="/collection">View Your Collection</b-btn>
      </div>
    </b-toast>

  </div>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig

export default {
  name: 'AddToCollectionBtn',
  props: {
    catalog: {
      type: String
    },
    title: {
      type: String,
      default: 'A new'
    },
    coinType: {
      type: String,
      default: 'coin'
    },
    buttonSmall: {
      type: Boolean,
      default: false
    },
    set: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      quantity: 0
    }
  },
  methods: {
    getQuantity: function () {
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .doc(this.catalog)
        .get().then((doc) => {
          if (doc.exists) {
            this.quantity = doc.data().quantity
            // console.log('Document data: ', doc.data())
          } else {
            // doc.data() will be undefined in this case
            // console.log('No such document!')
          }
        }).catch(function (error) {
          console.log('Error getting document: ', error)
        })
    },
    addToCollection () {
      const createdAt = new Date()
      userCollection
        .doc(this.$store.state.user.uid)
        .collection('userCoinCollection')
        .doc(this.catalog)
        .set({ catalog: this.catalog, quantity: 1, date_added: createdAt })
        .then(() => {
          console.log('Coin successfully added!')
          this.getQuantity()
          // userCollection.doc(this.$store.state.user.uid).update({ collectionTotal: firebaseDb.FieldValue.increment(1) }, { merge: true })
          this.$bvToast.toast(`Coin added successfully.`, {
            title: 'Coin Added',
            toaster: 'b-toaster-bottom-right',
            autoHideDelay: 5000
          })
        }).catch(function (error) {
          console.error('Error adding coin: ', error)
        })
      // Update collection total
      // userCollection
      //   .doc(this.$store.state.user.uid)
      //   .update({ collectionTotal: firebaseDb.FieldValue.increment(1) }, { merge: true })
      //   .then((doc) => {
      //     if (doc.exists) {
      //       console.log('Document data: ', doc.data())
      //     } else {
      //       // doc.data() will be undefined in this case
      //       console.log('No such document!')
      //     }
      //   }).catch(function (error) {
      //     console.log('Error getting document: ', error)
      //   })
    }
    // getPresscoins: function () {
    //   userCollection
    //     .doc(this.$store.state.user.uid)
    //     .collection('userCoinCollection')
    //     .onSnapshot(querySnapshot => {
    //       let presscoinsArray = []

    //       querySnapshot.forEach(doc => {
    //         let coin = doc.data()
    //         coin.id = doc.id
    //         presscoinsArray.push(coin)
    //       })

    //       this.presscoins = presscoinsArray
    //     })
    // },
    // save () {
    //   // save it to the firebase db.
    //   this.$store.dispatch('addAccountValue', { accountValue: this.catalog })
    //   // make toast
    //   this.$bvToast.show(this.catalog)
    //   // Increment collection count by one
    //   userCollection.doc(this.$store.state.user.uid).collectionTotal.increment(1)
    // }
  },
  beforeMount () {
    this.getQuantity()
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated()
    }
    // matchingCatalog () {
    //   if (this.presscoins.filter(e => e.catalog === this.catalog).length > 0) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/colors";
.add-to-collection-btn {
  display: inline-block;
  background-color: $color-success;
  border-radius: 3px;
  padding: 0.5rem;
  color: $color-white;
}
</style>
