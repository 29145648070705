import { render, staticRenderFns } from "./FeaturedJumbotron.vue?vue&type=template&id=dd8beaa2&scoped=true&"
import script from "./FeaturedJumbotron.vue?vue&type=script&lang=js&"
export * from "./FeaturedJumbotron.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedJumbotron.vue?vue&type=style&index=0&id=dd8beaa2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd8beaa2",
  null
  
)

export default component.exports