<template>
  <div class="current-listings">
    <b-container class="p-0 mb-4">
      <b-row>
        <b-col lg="4" v-for="contentNode in contentNodes" :key="contentNode.id">
          <b-card :title="contentNode.title"
                  :img-src="contentNode.image.url"
                  :img-alt="contentNode.title"
                  img-top
                  tag="article"
                  class="mb-2"
          >
          <vue-markdown v-if="contentNode.body != null">{{ contentNode.body }}</vue-markdown>
          <p><small class="text-muted">{{ contentNode.publishDate | moment("from", "now") }} | {{ contentNode.attachment.size | prettyBytes}}</small></p>
          <b-button :href="contentNode.attachment.url" target="_blank" variant="primary"><font-awesome-icon :icon="['far', 'file-download']" /> Download</b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import VueMarkdown from 'vue-markdown'

export const contentNodes = gql`
  query contentNodes {
    contentNodes( orderBy: publishDate_DESC, 
    where: {
        status: PUBLISHED,
        contentNodeType: Listing,
        body_not: ""
    }) {
      id
      contentNodeType
      title
      slug
      body
      publishDate
      image {
        url
      }
      attachment {
        url
        size
      }
    }
  }
`

export default {
  name: 'CurrentListings',
  components: { VueMarkdown },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  }
}
</script>
