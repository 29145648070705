<template>
  <div class="location">
    <div v-for="contentNode in contentNodes" v-bind:key="contentNode.id">
    <b-img v-if="contentNode.headerImage != null" :src="'https://media.graphcms.com/crop=dim:[0,0,1800,600]/resize=w:1800/compress/' + contentNode.headerImage.handle" :alt="contentNode.title" fluid-grow class="mb-5" />
    <b-container>
      <b-row>
        <b-col>
          <h1>{{ contentNode.title }} Pressed Coins</h1>
          <vue-markdown class="lead">{{ contentNode.body }}</vue-markdown>

          <h2>{{ contentNode.title }} Coin Press Machine Locations</h2>
          <machines-by-location-snippet :location="contentNode.title" :limit="200" />

          <h2 class="mt-4">New {{ contentNode.title }} Pressed Coins</h2>
          <pc-coins-by-location :location="contentNode.title" available="True" order="desc" />

          <b-btn :to="'/search/?refinementList%5BPlace%5D%5B0%5D=' + contentNode.title" variant="link">See All New {{ contentNode.title }} Pressed Coins <font-awesome-icon :icon="['far', 'angle-right']" /></b-btn>

          <h2>{{ contentNode.title }} Pressed Coin Listings, Checklists, Maps, & Guides</h2>
          <b-card-group deck class="mb-4">
            <b-card :title="contentNode.title"
                    img-top
                    tag="article"
                    class="mb-2"
                    v-for="contentNode in contentNodes.slice(0, 2)"
                    :key="contentNode.id"
            >
              <p class="card-text">{{ contentNode.excerpt }}</p>
              <p><small class="text-muted">{{ contentNode.publishDate | moment("from", "now") }}</small></p>
              <b-button :to="'/page/' + contentNode.slug" variant="primary">Read More</b-button>
            </b-card>
          </b-card-group>
          <b-btn to="/listings" variant="link">See All Pressed Coin Listings <font-awesome-icon :icon="['far', 'angle-right']" /></b-btn>

          <h2>Retired {{ contentNode.title }} Pressed Coins</h2>
          <pc-coins-by-location :location="contentNode.title" available="False" />

          <b-btn :to="'/search/?refinementList%5BPlace%5D%5B0%5D=' + contentNode.title" variant="link">See All {{ contentNode.title }} Retired Pressed Coins <font-awesome-icon :icon="['far', 'angle-right']" /></b-btn>

          <h2>{{ contentNode.title }} Pressed Coin News</h2>
          <blogs :location="contentNode.slug"></blogs>
          <b-btn to="/blog" variant="link">See All Articles <font-awesome-icon :icon="['far', 'angle-right']" /></b-btn>

          <h2>More Pressed Coin Locations</h2>
          <pc-locations/>
          <b-btn to="/locations" variant="link">See All Pressed Coin Locations <font-awesome-icon :icon="['far', 'angle-right']" /></b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import VueMarkdown from 'vue-markdown'
import LocationsSnippet from '@/components/LocationsSnippet'
import CoinsByLocationSnippet from '@/components/CoinsByLocationSnippet'
import MachinesByLocationSnippet from '@/components/MachinesByLocationSnippet'
import BlogsSnippet from '@/components/BlogsSnippet'

export const contentNodes = gql`
  query contentNodes($id: String!) {
    contentNodes( orderBy: publishDate_DESC, 
    where: {
        slug: $id,
        status: PUBLISHED,
        contentNodeType: Location
    }) {
      id
      title
      body
      slug
      publishDate
      excerpt
      headerImage {
        url
        handle
      }
    }
  }
`

export default {
  name: 'Location',
  metaInfo () {
    return {
      title: this.contentNodes[0].title + ' Pressed Coins',
      meta: [
        { name: 'description', content: this.contentNodes[0].body }
      ]
    }
  },
  components: {
    'pc-locations': LocationsSnippet,
    'blogs': BlogsSnippet,
    'pc-coins-by-location': CoinsByLocationSnippet,
    VueMarkdown,
    MachinesByLocationSnippet
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes,
      variables () {
        return {
          id: this.$route.params.slug
        }
      }
    }
  }
}
</script>
