<template>
  <div class="subscribe">
    <b-container class="text-center text-white p-4" fluid>
      <b-row>
        <b-col>
          <h2 class="subscribe-header"> Sign up to get the latest Presscoins updates and more. </h2>
          <h3 class="subscribe-subheader"> </h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form action="https://presscoins.us16.list-manage.com/subscribe/post?u=09eb052ce5a8aa72324328831&amp;id=145056cd57" method="post" name="mc-embedded-subscribe-form" target="_blank" novalidate>
            <b-form-input type="email" value="" name="EMAIL" placeholder="Email" maxlength="50" class="mb-2" style="max-width:20rem;margin:0 auto;"></b-form-input>
            <b-form-input type="text" value="" placeholder="Name" name="FNAME" maxlength="50" class="mb-2" style="max-width:20rem;margin:0 auto;"></b-form-input>
            <b-form-checkbox name="gdpr[1561]" value="Y" class="mb-2">I Agree to the <b-link to="/pages/terms-of-service">Terms of Service</b-link></b-form-checkbox>
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_09eb052ce5a8aa72324328831_145056cd57" tabindex="-1" value=""></div>
            <b-button type="submit" value="Subscribe" name="subscribe" variant="primary">Subscribe</b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MailchimpSubscribe'
}
</script>

<style lang="scss">
@import "src/assets/scss/colors";

.subscribe {
min-height: 18rem;
background-color: #58c70d;
background-repeat: no-repeat;
background-position: center right;
background-size: cover;
}

.subscribe a {
color: $color-brand-light-blue
}

.subscribe-header {
color: #121150;
}

.subscribe-subheader {
color: #fff;
}
</style>
