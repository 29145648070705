<template>
    <b-container fluid>
      <b-row align-v="center" style="min-height:78vh;background:url(https://res.cloudinary.com/presscoins/image/upload/v1568167219/contentNodes/login-bg.jpg) top/cover;">
        <b-col cols="12" md="6">
          <b-card class="login text-center mt-5 mb-5" style="max-width: 25rem;margin:0 auto">
            <h1>Log in</h1>
            <social-login />
            <p class="my-2 text-muted">or log in with email</p>
            <b-form-input
              type="text"
              v-model="email"
              placeholder="Email"
              maxlength="50"
              class="mb-2"
              required />
            <b-form-input
              type="password"
              v-model="password"
              placeholder="Password"
              maxlength="50"
              class="input"
              required />
            <b-link to="/forgot-password" class="d-block mb-3">Forgot password?</b-link>
            <b-button @v-on:lick="login" block variant="primary" class="mb-2">Log in</b-button>
            <p class="mb-0">New to Presscoins? <b-link to="/sign-up">Sign up now</b-link></p>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import SocialLogin from '@/components/SocialLogin'

export default {
  name: 'login',
  metaInfo: {
    title: 'Login',
    meta: [
      { name: 'description', content: 'Login to your Presscoins account and start your Walt Disney World pressed coin collection today.' }
    ]
  },
  data: function () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login () {
      this.$store.dispatch('userLogin', { email: this.email, password: this.password })
    }
  },
  components: {
    'social-login': SocialLogin
  }
}
</script>
