<template>
  <div class="locations">
    <b-jumbotron
      style="background:url(https://res.cloudinary.com/presscoins/image/upload/v1568166081/contentNodes/presscoins-walt-disney-world-pressed-coin-locations.jpg) no-repeat center / cover"
      header="Coin Press Locations"
      lead="Find pressed penny machines near Walt Disney World’s parks, resorts, and other locations."
      class="text-white"
      fluid>
    </b-jumbotron>

    <b-container class="mb-5">
      <b-row class="mb-4">
        <b-col>
          <h2>Walt Disney World Pressed Coin Locations</h2>
          <pc-locations/>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <h2>Coin Press Machines at EPCOT</h2>
          <machines-by-location-snippet location="Epcot" />

          <h2>Pressed Coins at EPCOT</h2>
          <pc-coins-by-location location="Epcot" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <h2>Coin Press Machines at Magic Kingdom</h2>
          <machines-by-location-snippet location="Magic Kingdom" />

          <h2>Pressed Coins at Magic Kingdom</h2>
          <pc-coins-by-location location="Magic Kingdom" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <h2>Coin Press Machines at Animal Kingdom</h2>
          <machines-by-location-snippet location="Animal Kingdom" />

          <h2>Pressed Coins at Animal Kingdom</h2>
          <pc-coins-by-location location="Animal Kingdom" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <h2>Coin Press Machines at Hollywood Studios</h2>
          <machines-by-location-snippet location="Hollywood Studios" />

          <h2>Pressed Coins at Hollywood Studios</h2>
          <pc-coins-by-location location="Hollywood Studios" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <h2>Coin Press Machines at Disney Springs</h2>
          <machines-by-location-snippet location="Disney Springs" />

          <h2>Pressed Coins at Disney Springs</h2>
          <pc-coins-by-location location="Disney Springs" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import LocationsSnippet from '@/components/LocationsSnippet'
import CoinsByLocationSnippet from '@/components/CoinsByLocationSnippet'
import MachinesByLocationSnippet from '@/components/MachinesByLocationSnippet'

export const contentNodes = gql`
  query contentNodes {
    contentNodes( orderBy: publishDate_DESC, 
    where: {
        status: PUBLISHED,
        contentNodeType: Location
    }) {
      id
      contentNodeType
      title
      slug
      publishDate
      headerImage {
        url
      }
    }
  }
`

export default {
  name: 'Locations',
  metaInfo: {
    title: 'Locations',
    meta: [
      { name: 'description', content: 'Find pressed penny machines near Walt Disney World’s parks, resorts, and other locations.' }
    ]
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  },
  components: {
    'pc-locations': LocationsSnippet,
    'pc-coins-by-location': CoinsByLocationSnippet,
    MachinesByLocationSnippet
  }
}
</script>
