<template>
    <b-jumbotron
      style="position:relative;background: url(https://res.cloudinary.com/presscoins/image/upload/v1568165907/contentNodes/presscoins-walt-disney-world-pressed-coin-account.jpg) no-repeat center / cover"
      header="The New Presscoins"
      lead="A bajillion new memory-making possibilities!"
      class="text-white mb-md-5"
      fluid>
      <b-button to="/page/about" variant="light" class="d-block d-md-inline-block mr-md-2 mb-2">Learn About the Changes</b-button>
      <b-button to="/collection" variant="outline-light" class="d-block d-md-inline-block mb-2">Start Your Collection</b-button>
      <SearchBar class="mt-4"></SearchBar>
    </b-jumbotron>
    <!-- <div class="pc-featured-jumbotron">
        <img src="../assets/img/shadow-hand.png" alt="Shadow Hand" class="pc-shadow-hand rotate360" />

        <h2 v-if="title" class="pc-featured-jumbotron__title">{{ title }}</h2>

        <div class="floating">
          <b-link to="/pressed-coin/MK0196" class="pc-featured-coin">
            <img src="../assets/img/presscoins-coin-graphic-1.png" alt="Featured Coin" class="pc-featured-coin-img" />
          </b-link>
        </div>

        <div class="floating-2">
          <b-link to="/pressed-coin/MK0196" class="pc-featured-coin">
            <img src="../assets/img/presscoins-coin-graphic.png" alt="Featured Coin" class="pc-featured-coin-img" />
          </b-link>
        </div>

        <div class="floating-3">
          <b-link to="/pressed-coin/MK0196" class="pc-featured-coin">
            <img src="../assets/img/presscoins-coin-graphic-3.png" alt="Featured Coin" class="pc-featured-coin-img" />
          </b-link>
        </div>

        <h3 v-if="subtitle" class="pc-featured-jumbotron__subtitle">{{ subtitle }}</h3>
        <p v-if="body" class="pc-featured-jumbotron__body">{{ body }}</p>
        <b-btn v-if="primaryActionTitle" :to="primaryActionUrl" variant="primary" class="pc-featured-jumbotron__primary-btn mb-2">{{ primaryActionTitle }}</b-btn>
        <b-btn v-if="secondaryActionTitle" :to="secondaryActionUrl" variant="secondary" class="pc-featured-jumbotron__secondary-btn ml-2 mb-2">{{ secondaryActionTitle }}</b-btn>
    </div> -->
</template>

<script>
import SearchBar from '@/components/SearchBar'

export default {
  name: 'featuredJumbotron',
  data () {
    return {
      title: 'TURN YOUR OWN QUARTER',
      subtitle: 'INTO A PRESSCOINS SOUVENIER',
      body: '',
      primaryActionUrl: '/collection',
      primaryActionTitle: 'Star Your Collection',
      secondaryActionUrl: '/search/?query=Ghost',
      secondaryActionTitle: 'See More Gholish Pressed Coins'
    }
  },
  components: {
    SearchBar
  }
}
</script>

<style lang="scss" scoped>
.pc-featured-jumbotron {
  display: block;
  width: 100%;
  // min-height: calc(100vh - 5.75rem);
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #495273;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 10rem #000000;
}

.pc-featured-jumbotron__title {
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 0;
  color: #dcc9aa;
  font-family: 'Belwe Std', serif;
  // -webkit-text-stroke: 1px #b4a388;
}

.pc-featured-jumbotron__subtitle {
  font-size: 4rem;
  line-height: 1;
  color: #dcc9aa;
  font-family: 'Belwe Std', serif;
  // -webkit-text-stroke: 1px #b4a388;
}

.pc-featured-coin {
  display: inline-flex;
  width: 12rem;
  height: 18rem;
  overflow: hidden;
  border-radius: 50%;
  background: #d8e1f9;
  margin: 0 2rem 2rem 2rem;
  padding: 0.75rem;
  box-shadow: 3px 3px 0.5rem rgba(#286efa,0.3);
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: rotate(20deg) translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;

  &:hover {
      transform: rotate(20deg) scale(1.02);
      box-shadow: 0 0 0 3px rgba(#286efa ,0.9), 3px 3px 1rem rgba(#286efa,0.5);
  }
}

.pc-featured-coin-img {
  display: block;
  mix-blend-mode: darken;
  max-width: 100%;
  // margin: 0.25rem;
}

.floating {
    animation-name: floating;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    display: inline-flex;
}

@keyframes floating {
    from { transform: translate(0,  0px) scale(1.1); }
    65%  { transform: translate(-10px, 15px) rotate(10deg) scale(1.25); }
    to   { transform: translate(0, -0px) scale(1.1); }
}

.floating-2 {
    transform-origin: 50% 50%;
    animation-name: floating-2;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    display: inline-flex;
}

@keyframes floating-2 {
    from { transform: translate(0,  0px) scale(1.2); }
    65%  { transform: translate(20px, 20px) rotate(-20deg) scale(1.1); }
    to   { transform: translate(0, -0px) scale(1.2); }
}

.floating-3 {
    transform-origin: 50% 50%;
    animation-name: floating-3;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    display: inline-flex;
}

@keyframes floating-3 {
    from { transform: translate(0,  0px) scale(1.15); }
    65%  { transform: translate(50px, 20px) rotate(30deg) scale(1.3); }
    to   { transform: translate(0, -0px) scale(1.15); }
}

.pc-shadow-hand {
  width: 100rem;
  position: absolute;
  top: -36rem;
  left: -30rem;
  z-index: 99;
  user-select: none;
  pointer-events: none;
}

.rotate360 {
    transform-origin: 0 50%;
    animation-name: rotate360;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: inline-flex;
}

@keyframes rotate360 {
    100% { transform:rotate(360deg); }
}
</style>
