<template>
    <div class="coins-by-location">
        <pc-card-carousel :autoplay="this.autoplay">
            <slide v-for="(p, index) in presscoins" :key="index" class="text-center">
                <b-link :to="'/pressed-coin/' + p.catalog" class="pc-coin">
                  <div>
                    <AddCoinFavBtn :catalog="p.catalog"/>
                    <b-img :src="'https://res.cloudinary.com/presscoins/image/upload/h_200,w_200,c_limit/d_contentNodes:presscoins-empty-coin-1.png/v1535414476/pressed-coins/' + p.catalog + '.jpg'" :alt="p.catalog" fluid></b-img>
                    <span class="pc-coin-title">{{ p.shortdesc }}</span> <b-badge variant="primary" v-if="p.available == 'True'">Available</b-badge> <b-badge variant="secondary" v-if="p.available == 'False'">Retired</b-badge>
                    <div v-if="p.variation" class="pc-coin-variation" v-b-tooltip.hover title="Coin with Variation"><img src="../assets/img/presscoins-variation.png" alt="Pressed Coin Variation" style="max-width: 1.5rem;" /></div>
                    <add-to-collection-btn :catalog="p.catalog" :title="p.shortdesc" :coinType="p.coin" :buttonSmall="true" class="pc-coin-add" />
                  </div>
                </b-link>
            </slide>
        </pc-card-carousel>
    </div>
</template>

<script>
import { presscoinsCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig
import CardCarousel from '@/components/CardCarousel'
import AddToCollectionBtn from '@/components/AddToCollectionBtn'
import AddCoinFavBtn from '@/components/AddCoinFavBtn'

export default {
  name: 'CoinsByLocationSnippet',
  props: {
    location: { // Location prop
      type: String,
      default: 'Magic Kingdom' // Default location if none specified from prop
    },
    available: {
      type: String,
      default: 'True'
    },
    limit: {
      type: Number,
      default: 24
    },
    order: {
      type: String,
      default: 'asc'
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      presscoins: [] // Empty array to populate from firestore
    }
  },
  methods: {
    getPresscoins: function () {
      presscoinsCollection
        .where('place', '==', this.location)
        .where('available', '==', this.available)
        .orderBy('firstseen', this.order)
        .limit(this.limit)
        .onSnapshot(querySnapshot => {
          let presscoinsArray = []

          querySnapshot.forEach(doc => {
            let coin = doc.data()
            coin.id = doc.id
            presscoinsArray.push(coin)
          })

          this.presscoins = presscoinsArray
        })
    }
  },
  beforeMount () {
    this.getPresscoins()
  },
  components: {
    'pc-card-carousel': CardCarousel,
    AddToCollectionBtn,
    AddCoinFavBtn
  }
}
</script>

<style lang="scss">
.pc-coin-add {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.5rem;
}

.pc-coin-variation {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5rem;
}
</style>
