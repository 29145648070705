// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

// Meta
import Meta from 'vue-meta'

// Geolocation
import Geolocation from 'vue-component-geolocation'

// Social Sharing
import SocialSharing from 'vue-social-sharing'

// Algolia
import InstantSearch from 'vue-instantsearch'
import VueAutosuggest from 'vue-autosuggest'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/default.scss'

// Vue Progress
import VueProgressBar from 'vue-progressbar'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faTh, faThList, faFileDownload, faLink, faUnlink, faSignOut, faLifeRing, faTrashAlt, faPlus, faMinus, faPrint, faCheck, faChevronLeft, faChevronRight, faMapSigns, faInfoCircle, faStars, faArrowAltCircleDown, faCog, faExclamationTriangle, faCommentAltLines, faTrash, faCommentAltPlus, faHeartBroken, faFilter, faCoffee, faUserCircle, faTimes, faSearch, faHeart } from '@fortawesome/pro-regular-svg-icons'
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons'
import { faTwitter, faFacebookF, faPinterest, faAlgolia, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Instagram
import VueInstagram from 'vue-instagram'

// Firebase & Firestrore
import VueFire from 'vuefire'

import { firebaseDb, firebaseAuth } from '@/plugins/firebaseConfig' // eslint-disable-line no-unused-vars

import { apolloProvider } from '@/plugins/apolloConfig'

// Filter Bytes
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'

// Segment
import VueSegmentAnalytics from 'vue-segment-analytics' // eslint-disable-line no-unused-vars

// https://www.npmjs.com/package/vue2-filters
// Standard Filter
import Vue2Filters from 'vue2-filters'

// Filter Numbers
import numFormat from 'vue-filter-number-format'

// Print
import Print from 'vue-print-nb'

import VueCarousel from 'vue-carousel'

import VueHtmlToPaper from 'vue-html-to-paper'

import * as VueGoogleMaps from 'vue2-google-maps'

import 'vue-googlemaps/dist/vue-googlemaps.css'

import VueGoogleMaps2 from 'vue-googlemaps'

import VueUnits from 'vue-units'

Vue.use(VueUnits)

Vue.use(VueGoogleMaps2, {
  load: {
    // your Google API key
    apiKey: 'AIzaSyBHu6Et0rhnQLaSJvhWz_EG7t4wEZbMMpY'
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBHu6Et0rhnQLaSJvhWz_EG7t4wEZbMMpY',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // If you want to set the version, you can do so:
    // v: '3.26',
  }
  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,
  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
})

// Stripe Payment Gateway
// import Stripe from 'stripe'

// const stripe = require('stripe')('sk_test_nE9vImFBvQZKC8wb2Pwa4q5b00mHy7o015')

// stripe.charges.retrieve('ch_1F8aoGEmConcGjs8KuLIOEj7', {
//   api_key: 'sk_test_nE9vImFBvQZKC8wb2Pwa4q5b00mHy7o015'
// })

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ]
}

Vue.use(VueHtmlToPaper, options)

Vue.use(VueCarousel)

Vue.use(Print)

Vue.filter('numFormat', numFormat)

Vue.use(Vue2Filters)

Vue.use(vueFilterPrettyBytes)

Vue.use(VueFire)

// Segment
// TODO: disable in development
Vue.use(VueSegmentAnalytics, {
  id: 'bfzfqH3BC4sa5KIEdYG0fESZXQRkAKpu',
  router // Optional
})

// Geolocation
Vue.use(Geolocation)

// Meta
Vue.use(Meta)

// Moment
Vue.use(require('vue-moment'))

// Social Sharing
Vue.use(SocialSharing)

// Font Awesome
library.add(faTwitter, faFacebookF, faPinterest, faCoffee, faUserCircle, faTimes, faSearch, faAngleRight, faTh, faThList, faAlgolia, faFileDownload, faLink, faUnlink, faSignOut, faLifeRing, faTrashAlt, faPlus, faMinus, faPrint, faCheck, faChevronLeft, faChevronRight, faMapSigns, faInfoCircle, faStars, faInstagram, faArrowAltCircleDown, faCog, faExclamationTriangle, faCommentAltLines, faTrash, faCommentAltPlus, faHeartBroken, faFilter, faHeart, fasHeart)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Loading Progress Bar
Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px'
})

// Algolia Search
Vue.use(InstantSearch)
Vue.use(VueAutosuggest)

// Boostrap
Vue.use(BootstrapVue)

// Instagram
Vue.use(VueInstagram)

Vue.config.productionTip = false
// handle page reloads
let app

firebaseAuth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({ // eslint-disable-line no-new
      el: '#app',
      apolloProvider,
      render: h => h(App),
      router,
      store
    })
  }
})
