<template>
  <div>
    <b-jumbotron
      style="background:url(https://res.cloudinary.com/presscoins/image/upload/v1568166145/contentNodes/presscoins-walt-disney-world-pressed-coin-shop.jpg) no-repeat center / cover"
      header="Pressed Coin Shop"
      lead="Discover your next big Disney family adventure with exclusive checklists, maps, & books for sale."
      class="text-white"
      fluid>
    </b-jumbotron>

    <b-container class="mb-4">
      <b-row>
        <b-col lg="6" class="mb-5" v-for="contentNode in contentNodes" :key="contentNode.id">
          <b-media>
            <b-img v-if="contentNode.image != null" :src="contentNode.image.url" rounded fluid class="mb-2" :alt="contentNode.title" />
            <b-link to="checkout">
              <h5>{{ contentNode.title }}</h5>
            </b-link>
            <p class="card-text" v-if="contentNode.excerpt != null">{{ contentNode.excerpt }}</p>
            <p class="card-text" v-if="contentNode.excerpt == null">{{ contentNode.body | truncate(120) }}</p>
            <b-btn to="checkout" variant="primary">Buy Now</b-btn>
          </b-media>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export const contentNodes = gql`
  query contentNodes {
    contentNodes( orderBy: publishDate_DESC, 
    where: {
        status: PUBLISHED,
        contentNodeType: Product
    }) {
      id
      contentNodeType
      title
      slug
      body
      publishDate
      image {
        url
      }
    }
  }
`

export default {
  name: 'Shop',
  metaInfo: {
    title: 'Shop Pressed Coins',
    meta: [
      { name: 'description', content: 'Discover your next big Disney family adventure with exclusive checklists, maps, & books for sale.' }
    ]
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  }
}
</script>
