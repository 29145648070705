<template>
  <div class="my-collection">
    <b-jumbotron
      style="background:url(https://res.cloudinary.com/presscoins/image/upload/v1568165970/contentNodes/presscoins-walt-disney-world-pressed-coin-collection.jpg) no-repeat center / cover"
      header="My Collection"
      lead="Your pressed coin collection, together at last."
      class="text-white mb-0"
      fluid>
    </b-jumbotron>

    <b-tabs pills card>
    <b-tab active>
    <template slot="title">Got It (<UserCoinCollectionCount/>)</template>
    <b-container class="p-0">
      <b-row>
        <b-col>
          <h2>Got It <span class="float-right">(<UserCoinCollectionCount/> Coins)</span></h2>
          <UserCoinCollection :limit="75" />

          <!-- <div v-if="accountTypePlus">
            <b-dropdown id="ddown-left" text="Theme" variant="outline-primary" class="m-2">
              <b-dropdown-item href="#">Cars</b-dropdown-item>
              <b-dropdown-item href="#">Toy Story</b-dropdown-item>
              <b-dropdown-item href="#">Aladin</b-dropdown-item>
            </b-dropdown>

            <b-dropdown id="ddown-left" text="Year" variant="outline-primary" class="m-2">
              <b-dropdown-item href="#">2019</b-dropdown-item>
              <b-dropdown-item href="#">2018</b-dropdown-item>
              <b-dropdown-item href="#">2017</b-dropdown-item>
              <b-dropdown-item href="#">2016</b-dropdown-item>
              <b-dropdown-item href="#">2015</b-dropdown-item>
            </b-dropdown>

            <b-dropdown id="ddown-left" text="Location" variant="outline-primary" class="m-2">
              <b-dropdown-item href="#">Magic Kingdom</b-dropdown-item>
              <b-dropdown-item href="#">Epcot</b-dropdown-item>
              <b-dropdown-item href="#">Animal Kingdom</b-dropdown-item>
              <b-dropdown-item href="#">Hollywood Studios</b-dropdown-item>
            </b-dropdown>

            <b-btn @click="gallery = true" variant="link" size="lg" v-b-tooltip.hover title="List View" class="px-2"><font-awesome-icon :icon="['far', 'th-list']" /></b-btn>
            <b-btn @click="gallery = false" variant="link" size="lg" v-b-tooltip.hover title="Gallery View" class="px-2"><font-awesome-icon :icon="['far', 'th']" /></b-btn>

            <b-btn to="" variant="outline-primary" class="float-right"><font-awesome-icon :icon="['far', 'print']" /> Print List</b-btn>
            <b-btn to="/search" variant="primary" class="float-right mr-2"><font-awesome-icon :icon="['far', 'plus']" /> Add Coin</b-btn>

            <b-row>
              <b-col cols="12" md="3" class="mb-4" v-for="index in 24" :key="index">
                <b-card class="text-center" no-body>

                  <b-card-body>
                    <b-link to="/pressed-coin/RES0291">
                      <img src="https://res.cloudinary.com/presscoins/image/upload/v1535338187/pressed-coins/MK0180.jpg" alt="2013 - Disney's Fab 5" style="max-width: 12rem; max-height: 12rem;">
                    </b-link>

                    <p class="mt-2 mb-0">
                      <b-link to="/remove" v-b-tooltip.hover title="Remove Coin" class="p-2"><font-awesome-icon :icon="['far', 'trash-alt']" /></b-link>
                      <b-input type="text" value="1" style="max-width: 3rem; display:inline-block" />
                      <b-link to="/add" v-b-tooltip.hover title="Add One" class="p-2"><font-awesome-icon :icon="['far', 'plus']" /></b-link>
                    </p>
                  </b-card-body>

                  <b-card-footer>RES0291 | Quarter</b-card-footer>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-pagination align="center" :total-rows="100" v-model="currentPage" :per-page="10" />
              </b-col>
            </b-row>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
    </b-tab>

    <!-- <b-tab title="Want It (XXXX)">
      <b-container>
        <b-row>
          <b-col>
            <h2>Want It <span class="float-right">(XXX Coins)</span></h2>
            <UserWantCollection :limit="25" />
          </b-col>
        </b-row>
      </b-container>
      </b-tab> -->

      <!-- <b-tab>
        <template slot="title">Couldn't Get It (XXXX)</template>
        <b-container>
          <b-row>
            <b-col>
              <h2>Couldn't Get It <span class="float-right">(XXX Coins)</span></h2>
              <UserUnobtainedCollection :limit="25" />
            </b-col>
          </b-row>
        </b-container>
      </b-tab> -->

      <!-- <b-tab>
        <template slot="title">Gave It (XXXX)</template>
        <b-container>
          <b-row>
            <b-col>
              <h2>Gave It <span class="float-right">(XXX Coins)</span></h2>
              <UserGaveCollection :limit="75" />
            </b-col>
          </b-row>
        </b-container>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import UserCoinCollection from '@/components/UserCoinCollection'
// import UserWantCollection from '@/components/UserWantCollection'
// import UserUnobtainedCollection from '@/components/UserUnobtainedCollection'
// import UserGaveCollection from '@/components/UserGaveCollection'
import UserCoinCollectionCount from '@/components/UserCoinCollectionCount'

export default {
  metaInfo: {
    title: 'Collection',
    meta: [
      { name: 'description', content: 'Your pressed coin collection, together at last.' }
    ]
  },
  data () {
    return {
      accountTypePlus: false
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated()
    }
  },
  components: {
    UserCoinCollection,
    // UserWantCollection,
    // UserUnobtainedCollection,
    // UserGaveCollection,
    UserCoinCollectionCount
  }
}
</script>
