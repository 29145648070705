import Vue from 'vue'

// Apollo
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'

// GRAPHCMS
const GRAPHCMS_API = 'https://api-useast.graphcms.com/v1/cjjc9bgcr0x2q01dhiwoobo8s/master'

// Create the apollo client
const apolloClient = new ApolloClient({
  link: new HttpLink({ uri: GRAPHCMS_API }),
  cache: new InMemoryCache(),
  connectToDevTools: true // TODO: take out for prod build.
})

// let loading = 0

const apolloProvider = new VueApollo({
  clients: {
    a: apolloClient
  },
  defaultClient: apolloClient,
  defaultOptions: {
    // $loadingKey: 'loading',
  },
  watchLoading (state, mod) {
    // loading += mod
    // console.log('Global loading', loading, mod)
  },
  errorHandler (error) {
    console.log('Global error handler')
    console.error(error)
  }
})

// Install the vue plugin
Vue.use(VueApollo)

export {
  apolloProvider
}
