<template>
  <div class="page">
    <div v-for="contentNode in contentNodes" v-bind:key="contentNode.id">

    <b-img v-if="contentNode.headerImage != null" :src="contentNode.headerImage.url" :alt="contentNode.title" fluid-grow class="mb-5" />

    <b-container class="mb-5" :class="{ 'mt-5' : contentNode.headerImage == null }">
        <b-row>
            <b-col lg="2">
            </b-col>
            <b-col lg="8">
              <h1>{{ contentNode.title }}</h1>

              <p class="lead" v-if="contentNode.subTitle != null">{{ contentNode.subTitle }}</p>

              <!-- :description="contentNode.socialDescription"
              :quote="contentNode.excerpt"
              :media="contentNode.socialImage.url"
              v-if="contentNode.socialDescription != null" -->
              <!-- <social-sharing url="https://beta.presscoins.com/"
                      :title="contentNode.title"
                      hashtags="presscoins"
                      twitter-user="presscoins"
                      class="mb-4"
                      inline-template>
                <div>
                  <network network="facebook">
                    <b-badge pill class="px-3 py-2" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/> Facebook</b-badge>
                  </network>
                  <network network="pinterest">
                    <b-badge pill class="px-3 py-2" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'pinterest' }"/> Pinterest</b-badge>
                  </network>
                  <network network="twitter">
                    <b-badge pill class="px-3 py-2" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/> Twitter</b-badge>
                  </network>
                </div>
              </social-sharing> -->

              <p class="lead" v-if="contentNode.intro != null">{{ contentNode.intro }}</p>

              <vue-markdown v-if="contentNode.body != null">{{ contentNode.body }}</vue-markdown>

              <hr />
              <p class="text-muted" v-if="contentNode.outro != null">{{ contentNode.outro }}</p>

              <social-sharing url="https://beta.presscoins.com/"
                      :title="contentNode.title"
                      hashtags="presscoins"
                      twitter-user="presscoins"
                      class="mb-4"
                      inline-template>
                <div>
                  <network network="facebook">
                    <b-badge pill class="px-3 py-2 b-facebook mr-1" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/> Facebook</b-badge>
                  </network>
                  <network network="pinterest">
                    <b-badge pill class="px-3 py-2 b-pinterest mr-1" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'pinterest' }"/> Pinterest</b-badge>
                  </network>
                  <network network="twitter">
                    <b-badge pill class="px-3 py-2 b-twitter" variant="primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/> Twitter</b-badge>
                  </network>
                </div>
              </social-sharing>
                <div v-if="contentNode.contributors != null" >
                <span v-for="contributor in contentNode.contributors" v-bind:key="contributor.id" >
                <b-img thumbnail fluid :src="contributor.profileImage.url" :alt="contributor.firstName + contributor.lastName" rounded="circle" width="75" height="75" />
                By
                <b-link :to="'/contributor/' + contributor.slug">{{ contributor.firstName }} {{ contributor.lastName }}</b-link>
                <span v-if="contributor.twitterHandle != null">| <b-link :href="'https://www.twitter.com/' + contributor.twitterHandle" target="_blank">@{{ contributor.twitterHandle }}</b-link> | </span>
                </span>
                </div>
                <span>{{ contentNode.publishDate | moment("dddd, MMMM Do YYYY") }}</span>

              <div v-if="contentNode.keywords != null">
                <b-link :to="'/keyword/' + keyword.slug" v-for="keyword in contentNode.keywords" v-bind:key="keyword.id" >
                  <b-badge pill class="px-3 py-2" variant="secondary">{{ keyword.keyword }}</b-badge>
                </b-link>
              </div>
          </b-col>
          <b-col lg="2">
          </b-col>
        </b-row>
    </b-container>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import VueMarkdown from 'vue-markdown'

export const contentNodes = gql`
  query contentNodes($id: String!) {
    contentNodes( orderBy: publishDate_DESC,
    where: { 
      slug: $id,
      status: PUBLISHED,
      contentNodeType: Page
    }) {
      id
      title
      slug
      subTitle
      publishDate
      intro
      outro
      body
      excerpt
      socialDescription
      headerImage {
        url
      }
      socialImage {
        url
      }
      contributors {
        firstName
        lastName
        slug
        twitterHandle
        profileImage {
          url
        }
      }
      keywords {
        keyword
        slug
      }
    }
  }
`

export default {
  name: 'PageOne',
  metaInfo () {
    return {
      title: this.contentNodes[0].title,
      meta: [
        // Schema.org for Google
        { name: 'name', content: this.contentNodes[0].title },
        { name: 'description', content: this.contentNodes[0].socialDescription },
        { name: 'image', content: this.contentNodes[0].socialImage.url },
        // Twitter
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.contentNodes[0].title },
        { name: 'twitter:description', content: this.contentNodes[0].socialDescription },
        { name: 'twitter:site', content: '@presscoins' },
        // { name: 'twitter:creator', content: '@jon_mayfield' },
        { name: 'twitter:image:src', content: this.contentNodes[0].socialImage.url },
        // { name: 'twitter:player', content: 'video.mp4' },
        // Open Graph general (Facebook, Pinterest & Google+)
        { name: 'og:title', content: this.contentNodes[0].title },
        { name: 'og:description', content: this.contentNodes[0].socialDescription },
        { name: 'og:image', content: this.contentNodes[0].socialImage.url },
        { name: 'og:url', content: 'https://beta.presscoins.com' },
        { name: 'og:site_name', content: 'Presscoins' },
        { name: 'og:locale', content: 'en_US' },
        // { name: 'og:video', content: 'video.mp4' },
        { name: 'fb:admins', content: '235725896469437' },
        { name: 'fb:app_id', content: '2072942123022764' },
        { name: 'og:type', content: 'article' },
        // Open Graph - Article
        { name: 'article:section', content: this.contentNodes[0].title },
        { name: 'article:published_time', content: this.contentNodes[0].publishDate },
        { name: 'article:author', content: 'Presscoins' },
        { name: 'article:tag', content: this.contentNodes[0].keywords.keyword }
        // { name: 'article:expiration_time', content: this.contentNodes[0].publishDate },
        // { name: 'article:modified_time', content: this.contentNodes[0].publishDate },
      ]
    }
  },
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes,
      variables () {
        return {
          id: this.$route.params.slug
        }
      }
    }
  },
  components: {
    VueMarkdown
  }
}
</script>
