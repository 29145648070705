<template>
    <router-link :to="to" class="pc-card" :style="'background-image: url(' + image + ')'">
        <h4 class="pc-card__title">{{ title }}</h4>
    </router-link>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: 'Card Title'
    },
    image: {
      type: String
    },
    to: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.pc-card {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #17213a;
  display: inline-flex;
  cursor: pointer;
  min-height: 10rem;
  min-width: 16rem;
  border-radius: 3px;
  box-shadow: 3px 3px 0.5rem rgba(#286efa,0.3);
  position: relative;
  transition: all 0.2s ease-in-out;
  padding: 1rem;
  margin: 0.5rem 0;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;

  &:hover {
      transform: scale(1.02);
      box-shadow: 0 0 0 3px rgba(#286efa ,0.9), 3px 3px 1rem rgba(#286efa,0.5);
  }

  .pc-card__title {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem;
      color: #fff;
      font-size: 2rem;
      text-shadow: 3px 3px 1rem rgba(#17213a,0.3);
  }
}
</style>
