<template>
  <div>
    <b-badge @click.stop="removeFromCollection(this.catalog)" variant="primary" v-b-tooltip.hover title="Change Quantity">
      Qty: 1
      <!-- {{this.quantity}} -->
    </b-badge>
    <!-- <b-modal :id="'coin-remove-' + p.catalog" centered title="Are you sure you want to remove this coin?">
      <p>Removing coins from your collection will remove any associated notes and quantites.</p>
    </b-modal> -->
  </div>
</template>

<script>
import { userCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig

export default {
  name: 'AddCoinQtyBtn',
  props: {
    catalog: {
      type: String,
      default: 'MAAK0018'
    }
  },
  methods: {
    removeFromCollection (id) {
      // userCollection.doc(state.user.uid).collection('userCoinCollection').add({
      //   catalog: model.accountValue,
      //   quantity: '1'
      // })
      // .doc("DC")
      userCollection.doc(this.$store.state.user.uid).collection('userCoinCollection').doc(id).delete().then(function () {
        console.log('Coin successfully deleted!')
        this.$bvToast.toast(`Coin removed successfully.`, {
          title: 'Coin Removed',
          autoHideDelay: 5000
        })
      }).catch(function (error) {
        console.error('Error removing coin: ', error)
      })
    }
  }
}
</script>
