var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{attrs:{"index-name":"prod_PRESSCOINS","search-client":_vm.searchClient,"routing":_vm.routing,"class-names":{
    'ais-InfiniteHits-loadMore': 'btn btn-primary',
  }}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"d-md-none float-right my-2",attrs:{"variant":"outline-primary","size":"sm"}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'filter']}}),_vm._v(" Filter")],1)],1),_c('b-col',{staticClass:"presscoin-sidebar",attrs:{"id":"collapse-2","cols":"12","md":"2"}},[_c('b-collapse',{staticClass:"d-md-block",attrs:{"id":"collapse-1"}},[_c('h4',[_vm._v("Coin Type")]),_c('ais-refinement-list',{staticClass:"mb-4",attrs:{"attribute":"coin","class-names":{
        'ais-RefinementList-count': 'badge badge-secondary'
      }}}),_c('h4',[_vm._v("Available")]),_c('ais-refinement-list',{staticClass:"mb-4",attrs:{"attribute":"available","class-names":{
          'ais-RefinementList-count': 'badge badge-secondary'
        }}}),_c('h4',[_vm._v("Location")]),_c('ais-refinement-list',{staticClass:"mb-4",attrs:{"attribute":"place","limit":5,"show-more":"","class-names":{
        'ais-RefinementList-showMore': 'btn btn-link btn-sm',
        'ais-RefinementList-count': 'badge badge-secondary'
      }}}),_c('h4',[_vm._v("Theme")]),_c('ais-refinement-list',{attrs:{"attribute":"theme","limit":5,"show-more":"","class-names":{
        'ais-RefinementList-showMore': 'btn btn-link btn-sm',
        'ais-RefinementList-count': 'badge badge-secondary'
      }}})],1)],1),_c('b-col',{staticClass:"pt-md-4 presscoin-content",attrs:{"cols":"12","md":"10"}},[_c('b-container',{staticClass:"mb-2 pc-search__sticky-bar"},[_c('b-row',[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('label',{staticClass:"d-block d-md-inline-block mb-0 mr-md-2"},[_vm._v("Search 2,000+ pressed coins")]),_c('small',{staticClass:"text-muted d-block d-md-inline-block mb-2"},[_vm._v("(Powered by "),_c('b-link',{attrs:{"href":"https://www.algolia.com","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'algolia']}}),_vm._v(" algolia")],1),_vm._v(")")],1),_c('b-input-group',{staticClass:"pc-search__input-group"},[_c('ais-search-box',{staticClass:"d-block",attrs:{"show-loading-indicator":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var currentRefinement = ref.currentRefinement;
      var isSearchStalled = ref.isSearchStalled;
      var refine = ref.refine;
return _c('input',{staticClass:"form-control form-control-lg d-block",staticStyle:{"border-radius":"6rem 0 0 6rem"},attrs:{"placeholder":"Search some of our favs like Mickey, Frozen, WDW19068","autocomplete":"off"},domProps:{"value":currentRefinement},on:{"input":function($event){return refine($event.currentTarget.value)}}})}}])}),_c('b-input-group-append',[_c('b-button',{staticClass:"px-3",attrs:{"variant":"primary"}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['far', 'search']}}),_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v("Search")])],1)],1)],1)],1)],1)],1),_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"p-0"},[_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var nbHits = ref.nbHits;
return _c('p',{staticClass:"mb-2"},[_vm._v("Showing "+_vm._s(_vm._f("numFormat")(nbHits))+" pressed coins")])}}])})],1)],1)],1),_c('b-container',{staticClass:"p-0 mb-4 text-center"},[_c('b-row',[_c('b-col',{staticClass:"p-0 "},[_c('b-list-group',{staticClass:"pc-search_list"},[_c('ais-infinite-hits',{staticClass:"mb-2",attrs:{"class-names":{
                'ais-InfiniteHits-loadMore': 'btn btn-primary mt-2 mx-auto',
                'ais-InfiniteHits-item': 'list-group-item list-group-item-action text-left',
                'ais-InfiniteHits-loadMore--disabled': 'd-none'
              }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return _c('router-link',{staticClass:"pc-search__item",attrs:{"to":'/pressed-coin/' + item.catalog}},[_c('b-container',{staticClass:"position-relative"},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-center p-0",attrs:{"cols":"5","md":"4"}},[_c('b-img',{staticClass:"pc-search__img",attrs:{"src":'https://res.cloudinary.com/presscoins/image/upload/h_200,w_200,c_limit/d_contentNodes:presscoins-empty-coin-1.png/v1535414476/pressed-coins/' + item.catalog + '.jpg',"alt":item.shortdesc,"fluid":""}})],1),_c('b-col',{attrs:{"cols":"7","md":"8"}},[_c('h5',{staticClass:"mb-1 pc-search_title"},[_c('add-to-collection-btn',{staticClass:"d-inline-block mr-1",attrs:{"catalog":item.catalog,"title":item.shortesc,"coinType":item.coin,"buttonSmall":true}}),_c('AddCoinFavBtn',{staticClass:"mr-1",attrs:{"catalog":item.catalog,"inline":""}}),_vm._v(" "+_vm._s(item.shortdesc))],1),_c('p',{staticClass:"mb-1 d-none d-md-block"},[_vm._v(_vm._s(item.description))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.catalog)+" ")]),(item.available == 'True')?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("Available")]):_vm._e(),(item.available == 'False')?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Retired")]):_vm._e()],1)],1)],1)],1)}}])})],1)],1)],1)],1),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var query = ref.query;
              var hits = ref.hits;
return [(hits.length)?_c('div'):_vm._e(),(!hits.length)?_c('div',{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"100%","max-width":"10rem"},attrs:{"src":require("../assets/img/machine-waypoint.png"),"alt":"404 Uh-oh"}}),_c('h2',[_vm._v("No pressed coins found for "),_c('i',[_vm._v(_vm._s(query))]),_vm._v(".")])]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }