<template>
  <div class="locations-snippet">
    <pc-card-carousel>
      <slide v-for="contentNode in contentNodes" :key="contentNode.id">
      <pc-card :to="'/location/' + contentNode.slug"
               :title="contentNode.title"
               :image="'https://media.graphcms.com/resize=width:400/' + contentNode.headerImage.handle"
      />
      </slide>
    </pc-card-carousel>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import CardCarousel from '@/components/CardCarousel'
import Card from '@/components/Card'

export const contentNodes = gql`
  query contentNodes {
    contentNodes( orderBy: nodeSort_ASC 
    where: {
        status: PUBLISHED,
        contentNodeType: Location
    }) {
      id
      contentNodeType
      title
      slug
      publishDate
      headerImage {
        handle
      }
    }
  }
`

export default {
  name: 'Locations',
  data: () => ({
    loading: 0,
    contentNodes: null
  }),
  apollo: {
    $loadingKey: 'loading',
    contentNodes: {
      query: contentNodes
    }
  },
  components: {
    'pc-card-carousel': CardCarousel,
    'pc-card': Card
  }
}
</script>
