<template>
    <div class="coins-by-location">
        <pc-card-carousel>
            <slide v-for="(p, index) in groups" :key="index" class="text-center">
                <b-link :to="'/machine/' + p[0].machine" class="pc-machine-dot">
                  <img :src="'https://res.cloudinary.com/presscoins/image/upload/c_fill,h_300,q_auto,w_300/d_contentNodes:presscoins-default.png/v1558741575/machines/' + p[0].machine + '.jpg'" :alt="p[0].machine" />
                  <span class="pc-coin-title">{{ p[0].location }}</span>
                </b-link>
            </slide>
        </pc-card-carousel>
    </div>
</template>

<script>
import { presscoinsCollection } from '@/plugins/firebaseConfig' // Import presscoinsCollection from firebaseConfig
import CardCarousel from '@/components/CardCarousel'
import { groupBy } from 'lodash'

export default {
  name: 'CoinsByLocationSnippet',
  props: {
    location: { // Location prop
      type: String,
      default: 'Magic Kingdom' // Default location if none specified from prop
    },
    available: {
      type: String,
      default: 'True'
    },
    limit: {
      type: Number,
      default: 24
    }

  },
  data () {
    return {
      presscoins: [] // Empty array to populate from firestore
    }
  },
  methods: {
    getPresscoins: function () {
      presscoinsCollection
        .where('place', '==', this.location)
        .where('available', '==', this.available)
        .orderBy('firstseen', 'asc')
        .limit(this.limit)
        .onSnapshot(querySnapshot => {
          let presscoinsArray = []

          querySnapshot.forEach(doc => {
            let coin = doc.data()
            coin.id = doc.id
            presscoinsArray.push(coin)
          })

          this.presscoins = presscoinsArray
        })
    }
  },
  beforeMount () {
    this.getPresscoins()
  },
  computed: {
    groups () {
      return groupBy(this.presscoins, 'machine')
    }
  },
  components: {
    'pc-card-carousel': CardCarousel
  }
}
</script>

<style lang="scss">
.pc-machine-dot {
  width: 16rem;
  height: 16rem;
  // margin-top: -2rem;
  // width: 100%;
  background: #286efa;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  box-shadow: 3px 3px 0.5rem rgba(40, 110, 250, 0.3);
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;

  img {
    width: 16rem;
    height: 16rem;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0 0 3px rgba(#286efa ,0.9), 3px 3px 1rem rgba(#286efa,0.5);
  }

  .pc-coin-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 1.25rem;
    line-height: 1.2;
    padding: 1rem 0.5rem;
    font-weight: normal;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: 'Gilroy-Heavy', Helvetica, Arial, sans-serif;
    background: -moz-linear-gradient(top,  rgba(23,33,58,0) 0%, rgba(23,33,58,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(23,33,58,0) 0%,rgba(23,33,58,1) 100%);
    background: linear-gradient(to bottom,  rgba(23,33,58,0) 0%,rgba(23,33,58,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0017213a', endColorstr='#17213a',GradientType=0 );
  }
}
.pc-coin-variation {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5rem;
}
</style>
