<template>
    <div class="pc-card-group">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'CardGroup'
}
</script>

<style lang="scss">
.pc-card-group {
    display: grid;
    grid-gap: 0 0.5rem;
    grid-template-columns: repeat(auto-fill,minmax(10rem, 1fr));
    margin-bottom: 1rem;

    .pc-coin {
        width: 100%;
        max-height: 10rem;

        img {
            max-width: 9rem;
            max-height: 9rem;
        }
    }
}

@media(min-width:42rem) {
    .pc-card-group {
        grid-gap: 0.75rem 1rem;
        grid-template-columns: repeat(auto-fill,minmax(16rem, 1fr));

        .pc-coin {
            max-height: 16rem;

            img {
                max-width: 13rem;
                max-height: 13rem;
            }
        }
    }
}
</style>
